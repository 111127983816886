import React,{useState,useEffect} from 'react'
import { getAllAboutUsIconAndDescription } from '../../../api/AboutUsApi';
import SETTING from '../../../modules/config/settings'

function IconSection({ parser, setLoader3 }) {
	const [aboutUsData, setAboutUsData] = useState([]);

	useEffect(() => {
		getDataHandler();
	}, [])

	const getDataHandler = async() => {
		try {
			setLoader3(true);
			const { data, status } = await getAllAboutUsIconAndDescription();
			if (status === 200) {
				setLoader3(true);
				if (data.response.status === 1) {
					setAboutUsData(data.response.data);
					
				} else {
					setAboutUsData([]);
				}
			}
			else {
				setAboutUsData([]);
			}
			setLoader3(false);
		} catch (e) {
			setLoader3(false);
			console.log(e, "Error in the About Us Icons section");
		}
	}

  return (
	<> 
		<section className="section-padding">
			<div className="container">
				{aboutUsData.map((item) =>
					<div className="row" key={item?.aboutUsIconId}>
						<div className="col-lg-2 col-md-2 col-sm-12 ms-auto">
							<div className="offshore-img text-center">
								<img src={`${SETTING.ICON_API_URL_ABOUT_US}${item.aboutUsIcon}`} alt='icon' className="img-fluid"/>
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-10 col-12 me-auto">
							<div className="offshore-content bg-lightgrey">
								{parser(item?.aboutUsIconContent)}
							</div>
						</div>
					</div>)
				}
			</div>
		</section>
	</>	
  )
}

export default IconSection