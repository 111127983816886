import axios from "axios";
import BaseUrl from '../modules/config/BaseUrl';

export const getAllSectionNewsAndInsightDetails = () => {
    return axios({
        method: 'GET',
        url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetNewsInsightsUserDetails`,
    });
};

export const getSectionByIdnNewsAndInsightDetails = (id) => {
    return axios({
        method: 'GET',
        url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetNewsInsightsDetailsByID?NewsInsightsMasterId=${id}`,
    });
};

export const outLookHistory = (PageNo,PageSize)=>{

	return axios({
		method:'GET',
		url:`${BaseUrl.UserApiUrl}/AboutUsUser/GetOutlookDetailsWithPaginationUser?PageNo=${PageNo}&PageSize=${PageSize}`
	})
}