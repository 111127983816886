import React, { useEffect, useState } from 'react'
import midbrdr from '../../../../assets/images/home/midbrdr.png';
import { getAllWeAreIcon , getAllWeAreContent } from "../../../../api/WeAreApi";
import parse from 'html-react-parser';
import SETTING from "../../../../modules/config/settings";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const WeAre = ({ setLoader3, setLoader3A }) => {
    const [weAreItems, setWeAreItems] = useState([])
    const [weAreIcon , setWeAreIcon] = useState({})

    useEffect(() => {
        getDataHandler();
        getDataImageHandler()
    }, [])

    const getDataHandler = async () => {
        try {
            setLoader3(true);
            const { data, status } = await getAllWeAreContent();
            if (status === 200) {
                setLoader3(false);
                if (data.response.status === 1) {
                    setWeAreItems(data.response.data);
                } else {
                    setWeAreItems([]);
                }
            }
            else {
                setWeAreItems([]);
            }

        } catch (e) {
            setLoader3(false);
            console.log(e, "Error in the We Are section");
        }
    }

    const getDataImageHandler = async () => {
        try {
            setLoader3A(true);
            const { data, status } = await getAllWeAreIcon();
            if (status === 200) {
                setLoader3A(false);
                if (data?.response?.status === 1) {
                    setWeAreIcon(data?.response?.data[0]);
                } else {
                    setWeAreIcon([]);
                }
            }
            else {
                setWeAreIcon([]);
            }

        } catch (error) {
            console.log(error);
            setLoader3A(false);
        }
    }


    return (
        <>
            <section className="conscious">
                <div className="container-fluid">
                    <div className="row p-0">
                        {/* {
                            weAreItems.map((item, idx) => */}
                                                {/* <img src={`${SETTING.IMAGE_API_URL_WEARE}${weAreIcon.qualitiesImageId}`} alt='mid_border' className="img-fluid" /> */}

                                <>
                                    <div className="col-lg-4 col-md-4 col-12 p-0 align-center-md">
                                {weAreIcon.qualitiesImageName ? <img src={`${SETTING.IMAGE_API_URL_WEARE}${weAreIcon.qualitiesImageName}`} alt='left_image' className="img-fluid block-img" /> : <img alt='left_image' className="img-fluid block-img" />}
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-12 align-self-center">
                                        <div className="conscious-box">
                                            <div className="conscious-box-content border-right">
                                               {weAreItems[0] ? parse(weAreItems[0]?.qualitiesName) : ""}
                                                {weAreItems[0] ? parse(weAreItems[0]?.qualitiesContentParagraph) : ""}
                                            </div>
                                            <div className="conscious-box-content">
                                               {weAreItems[1] ? parse(weAreItems[1]?.qualitiesName) : ""}
                                                {weAreItems[1] ? parse(weAreItems[1]?.qualitiesContentParagraph) : ""}
                                            </div>
                                            <div className="conscious-box-img">
                                                <LazyLoadImage src={midbrdr} alt='mid_border' effect='blur'className="img-fluid" />
                                            </div>
                                            <div className="conscious-box-content border-right">
                                               {weAreItems[2] ? parse(weAreItems[2]?.qualitiesName) : ""}
                                                {weAreItems[2] ? parse(weAreItems[2]?.qualitiesContentParagraph) : ""}
                                            </div>
                                            <div className="conscious-box-content">
                                               {weAreItems[3] ? parse(weAreItems[3]?.qualitiesName) : ""}
                                                {weAreItems[3] ? parse(weAreItems[3]?.qualitiesContentParagraph) : ""}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            {/* )
                        } */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default WeAre