import './App.css';
import { BrowserRouter, useLocation } from "react-router-dom";
import Content from './components/content/Content';
import ScrollToTop from './components/content/ScrollToTop';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import metaConfig from './metaConfig';

const MetaTags = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const meta = metaConfig[pathname];

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords.join(', ')} />
    </Helmet>
  );
};
function App() {
  return (
    <HelmetProvider>
      <BrowserRouter basename ="/">
        <ScrollToTop/>
        <MetaTags />
        <div className="App">
          <Content />
        </div>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
