import React, { useEffect, useState } from 'react'
import { getBannerImagesByPageId } from '../../../api/BannerApi';
import SETTING from '../../../modules/config/settings';
import SkeletonLoader from '../Skeleton/SkeletonLoader';

function BannerComponent({imageAlt, pageId,setLoader1,setNewLoading}) {
  const [bannerData,setBannerData] = useState([]);
  useEffect(() => {
    pageId && getBannerTableData(pageId)
}, [pageId])

const getBannerTableData = async (pageId) => {
    setLoader1(true);
    try {
        const { data, status } = await getBannerImagesByPageId(pageId);
        const res = data.response;
        //Table data
        if (status === 200) {
            if (res.status === 1) {
                const filterData = res.data.filter(val => val.active === true)
    
              const img = filterData ? filterData[0].bannerImage : []
                setBannerData(img);
            } else {
                setBannerData([]);
            }
        } else {
            setBannerData([]);
        }
        setLoader1(false);
    } catch (e) {
        setLoader1(false);
        console.log(e, "Error in the Single Banner");
    }
}

const handleImageLoad = () => {
    setNewLoading(true);
};
    
  return (
    <>
    {   bannerData?.length < 1 ? <SkeletonLoader variant="rectangular" width='100%' height={300} /> :
    <section className="banner-section">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 p-0">
                    <img src={SETTING.BANNER_IMAGE_API_URL + bannerData} className="img-fluid w-100" alt={imageAlt} onLoad={handleImageLoad}/>
                </div>
            </div>
        </div>
    </section>
    }
    </>
  )
}

export default BannerComponent