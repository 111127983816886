import { create } from "zustand";

export const useFooterDataStore = create((set) => (
    {
        locationAddressData: [],
        contactUsData: [],
        socialIconsData: [],
        footerDescriptionData: '',
        footerSignatoryData: [],
        footerCopyrightData: '',
        disclaimerActive: false,
        privacyActive: false,
        rulesActive: false,
        disclaimerActiveCount: 0,
        privacyActiveCount: 0,
        rulesActiveCount: 0,
        setLocationAddressApiData: (data) => set((state) => ({locationAddressData : data})),
        setContactUsApiData: (data) => set((state) => ({contactUsData: data})),
        setContactUsSocialApiData: (data) => set((state) => ({ socialIconsData : data})),
        setFooterDescriptionApiData: (data) => set({ footerDescriptionData : data }),
        setFooterSignatoryApiData: (data) => set((state) => ({ footerSignatoryData : data})),
        setFooterCopyrightApiData: (data) => set({ footerCopyrightData : data }),
        setDisclaimerActive: (data) => set({ disclaimerActive : data }),
        setPrivacyActive: (data) => set({ privacyActive : data }),
        setRulesActive: (data) => set({ rulesActive : data }),
        setDisclaimerActiveCount: (data) => set({ disclaimerActiveCount : data }),
        setPrivacyActiveCount: (data) => set({ privacyActiveCount : data }),
        setRulesActiveCount: (data) => set({ rulesActiveCount : data }),
    }
))
