import React,{useState,useEffect} from "react";
import quotes from "../../../../assets/images/home/quotes.png";
import redhalfcircle from "../../../../assets/images/news/red-halfcircle.png";
import Carousel from "react-multi-carousel";
import parse from 'html-react-parser';
import { getCeoDeskData } from "../../../../api/CeoOurTeamApi";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,

  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,

  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,

  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  return (
    <div className="carousel-button-group"> 
      <Button className={currentSlide === 0 ? ' owl-prev disable' : 'owl-prev'} onClick={() => previous()} />
      <Button className="owl-next" onClick={() => next()} />
      
    </div>
  );
};

const CeoDesk = ({ setLoader5 }) => {


  const [ceoDeskData, setCeoDeskData] = useState([]);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [play, setPlay] = useState(true)

  useEffect(() => {
   GetCeoDetails();
  }, [])

  const GetCeoDetails = async () => {

    setLoader5(true);
    await getCeoDeskData().then(res => {

        if (res.status === 200) {
            if (res.data.response.status === 1) {
            let ceoData = res.data.response.data;
              setCeoDeskData(ceoData);
          }
      }
      
    }).catch(e => {
            setLoader5(false);
            console.log(e, "Error in the CEO Desk section from Homepage");
    }).finally(e => {
            setLoader5(false)
    });  

  }



  return (
    <>
      <section className="section-padding ceo-desk pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                <h1>
                  The NAMS <span className="text-red">CEO’s Desk</span>
                </h1>
              </div>
            </div>
            <div className="ceodesk react-multi col-12">
            {
                ceoDeskData.length <= 1 ?
             
                  <div>
                    {ceoDeskData && ceoDeskData.map((item, id) => <div key={item.ceoDeskId} className="ceo-desk-box">
                      <div className="ceo-desk-box-img">
                        <img src={item?.imageUrl} alt='ceo_img' className="img-fluid" />
                      </div>
                      <div className="ceo-desk-box-content">
                        <h2 className="text-red">{item?.directorName}</h2>
                        <img src={quotes} alt='quotes' className="img-fluid quotes" />
                        <div className="ceo-mission">

                          <div className="text-content ">
                            {parse(item?.description)}
                          </div>
                          <div className="ellipsis">...</div>
                        </div>
                        <div className="btn-singapore">
                          <Link onClick={(e) => (e.preventDefault(), setShow(true), setModalData(item), setPlay(false))}>Read More</Link>
                        </div>
                        <div className="ceo-position">{parse(item?.summary)}</div>
                      </div>
                    </div>)}
                  </div>
              :
                  <Carousel responsive={responsive} draggable={false} arrows={false} showDots={false} renderButtonGroupOutside={true} infinite={true}
                    autoPlay={play ? true : false}
                    autoPlaySpeed={7000}
                    keyBoardControl={true}
                    customButtonGroup={<ButtonGroup />}
                    customTransition="all 1000ms" transitionDuration="1000"
                    className='owl-theme lg-12' nav="true" id="ceo-desk" style={{ position: "relative" }}>
                    {ceoDeskData && ceoDeskData.map((item, id) => <div key={item.ceoDeskId} className="ceo-desk-box">
                      <div className="ceo-desk-box-img">
                        <img src={item?.imageUrl} alt='ceo_img' className="img-fluid" />
                      </div>
                      <div className="ceo-desk-box-content">
                        <img src={quotes} alt='quotes' className="img-fluid quotes" />
                        <div className="ceo-mission">

                          <div className="text-content ">
                            {parse(item?.description)}
                          </div>
                          <div className="ellipsis">...</div>
                        </div>
                        <div className="btn-singapore">
                          <Link onClick={(e) => (e.preventDefault(), setShow(true), setModalData(item), setPlay(false))}>Read More</Link>
                        </div>
                        <h2 className="text-red">{item?.directorName}</h2>
                        <div className="ceo-position">{parse(item?.summary)}</div>
                      </div>
                    </div>)}
                  </Carousel>
            }          
            </div>
            <div className="col-12">
              <div className="halfred-circle text-center">
                <img
                  src={redhalfcircle}
                  alt="red_circle"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
       
      </section>
      <Modal isOpen={show} toggle={() => setShow(!show)} size='lg' centered >
        <ModalHeader toggle={() => (setShow(!show), setPlay(true))} className="border-0" >
          <div className="border-0 home-modal modal-ui-hd">
            <div className="text-red">{modalData?.directorName}</div>
            <p className="ceo-position" dangerouslySetInnerHTML={{ __html: modalData?.summary }}></p>
          </div>
        </ModalHeader>
        <ModalBody className='modal-ui '>
          <div dangerouslySetInnerHTML={{ __html: modalData?.description }}></div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CeoDesk;
