import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { getOurTeamData } from '../../../../api/CeoOurTeamApi';
import parse from 'html-react-parser';
import DummyTeam from '../../../../assets/images/teams/dummy.jpg'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,

    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,

    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,

    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const OurTeam = ({ setLoader7 }) => {
  
    const [teamData, setTeamData] = useState([]);
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState({});
    const [play, setPlay] = useState(true)
    // const [position, setPosition] = useState({startPosition: '0'});

    // const currentSlideIndex = useRef(0);
    
    useEffect(() => {
        GetOurTeamDetails();
    }, [])
   

    const GetOurTeamDetails = async() => {
    
            setLoader7(true);
            await getOurTeamData().then(res => {
                if (res.status === 200) {
                    if (res.data.response.status === 1) {
                        let ourTeamData = res.data.response.data;
                        setTeamData(ourTeamData)
                    }
                }
            }).catch(e => {
                setLoader7(false);
                console.log(e, "Error in the News & Insights section from Homepage");
            }).finally(e => setLoader7(false));  
        
    }
    // const updateCarouselPosition = (object) => {
    //     if (object.item.index != this.state.startPosition) { setPosition({ startPosition: object.item.index }); }
    // }

    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
            <div className="carousel-button-group">
                <Button className={currentSlide === 0 ? ' owl-prev disable' : 'owl-prev'} onClick={() => previous()} />
                <Button className="owl-next" onClick={() => next()} />

            </div>
        );
    };

  return (
      <section className="section-padding our-team-sec">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <div className="section-title text-center">
                          <h1>Our <span className="text-red">Team</span></h1>
                      </div>
                  </div>
                  <div className="ourteam react-multi col-12">
                      <Carousel className='owl-theme lg-12' id="our-team-slider" responsive={responsive} draggable={false} arrows={false} showDots={false} renderButtonGroupOutside={true} infinite={true}
                          autoPlay={play ? true : false}
                          autoPlaySpeed={7000}
                          customTransition="all 1000ms" transitionDuration="1000"
                          keyBoardControl={true} customButtonGroup={<ButtonGroup />}>
                          {teamData && teamData.map((item,id) => <div key={item.ourTeamId} className="our-team-box">
                                  <div className="our-team-content">
                                    <div className='team-height'>
                                      <div className="our-team-content-name">
                                      <h4 className="text-red">{item.directorName}</h4>
                                      <div className="team-position">{parse(item.summary)}</div>
                                      </div>
                                      </div>
                                  <div className="team-desc">
                                    <div className='text-content'>
                                    {parse(item.description)}
                                    </div>
                                    <div className="ellipsis">...</div>
                                    </div>
                                      <div className="btn-singapore">
                                      <Link id='OurTeamBtn' onClick={(e) => (e.preventDefault(), setModalData(item), setShow(true), setPlay(false))}>Read More</Link>
                                      {/* <Link onClick={(e) => setToTop(e,"\OurTeam")}>Read More</Link> */}
                                      </div>
                                  </div>
                                  <div className="our-team-image">
                                  <LazyLoadImage
                                       src={item?.imageUrl !== "" ? item?.imageUrl : DummyTeam}
                                        alt='teamImg'
                                        className="img-fluid"
                                        // loading='lazy'
                                        effect='blur'
                                         />
                                </div>
                          </div>)}
                         {/* <div class="owl-nav"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">sss</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">fff</span></button></div> */}
                      </Carousel>
                  </div>
              </div>
          </div>
          <Modal id="myModal" isOpen={show} toggle={() => setShow(!show)} size='lg' centered >
              <ModalHeader className="border-0" toggle={() => (setShow(!show), setPlay(true))} >
                  <div className="border-0 home-modal modal-ui-hd">
                      <div className="text-red">{modalData.directorName}</div>
                      <div dangerouslySetInnerHTML={{ __html: modalData?.summary }}></div>
                  </div> 
                  {/* <div className="team-position" >{parse(modalData.summary)}</div> */}
            </ModalHeader>
              <ModalBody className='modal-ui '>
                  {/* <div className='ms-4'>{parse(modalData.description)}</div> */}
                  <div className='' dangerouslySetInnerHTML={{ __html: modalData.description }}></div>
              </ModalBody>
          </Modal>
      </section>
  )
}

export default OurTeam