const BaseUrl = {
	// MainGateWayUrl: 'https://nams.nipponassetmgt.com',
	// AuthenticationUrl: 'http://nams.softlabsgroup.in',
	// UserApiUrl: 'https://namsapi.softlabsgroup.in/api',
	// AdminApiUrl: 'https://namsadminapi.softlabsgroup.in/api',
	//UserApiUrl: 'http://10.199.15.47:5010/api',
	//AdminApiUrl: 'https://singapore.nipponindiaim.com/newwebsiteadminapi/api',
	AdminApiUrl: 'https://singaporeadmin.nipponindiaim.com:5011/api',
	UserApiUrl: 'https://singapore.nipponindiaim.com/newwebsiteapi/api', 
   //AdminApiUrl: 'https://namsadminapi.softlabsgroup.in/api',
	// UserApiUrl: 'http://localhost:5010/api',
	// AdminApiUrl: 'http://localhost:5011/api',
	// AdminApiUrl: 'https://localhost:7029/api',
	// UserApiUrl: 'https://localhost:7154/api',
};
export default BaseUrl;