import React, { useEffect, useState } from 'react'
import { GetAllFooterPrivacyPolicy } from '../../../api/FooterApi'
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import Loader from '../../common/Loader/Loader';

const PrivacyPolicy = () => {

  useEffect(() => {
    getFooterPrivacyPolicy();

  }, [])
  

  const [privacyPolicyContentDescription, setPrivacyPolicyContentDescription] = useState('');
  const [privacyPolicyContentTitle, setPrivacyPolicyContentTitle] = useState('');

  const [loader, setLoader] = useState(false);

  const getFooterPrivacyPolicy = async () => {
    try {
        setLoader(true);
        const { data, status } = await GetAllFooterPrivacyPolicy();
        if (status === 200) {
            setLoader(false);
            if (data?.response?.status === 1) {
                setPrivacyPolicyContentDescription(data?.response?.data[0]?.footerContentParagraph);
                setPrivacyPolicyContentTitle(data?.response?.data[0]?.footerContentHeading)
            } else {
                setPrivacyPolicyContentDescription('');
                setPrivacyPolicyContentTitle('')
            }
        }
        else {
            setPrivacyPolicyContentDescription('');
            setPrivacyPolicyContentTitle('')
        }
  
    } catch (error) {
        console.log(error);
        setLoader(false);
    }
  }

  return (
      <>
       {/* {loader && <Loader />} */}
          <Helmet>
              <title>Privacy Policy - NAM Singapore</title>
              <meta name="description" content="Helmet application" />
          </Helmet>
    <section className="section-padding our-team-sec">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <div className="section-title text-center">
                          {parse(privacyPolicyContentTitle)}
                      </div>
                  </div>
                  <div className="col-12">
   
        <div>{parse(privacyPolicyContentDescription)}</div>
        </div>
        </div>
        </div>
        </section>
    </>
  )
}

export default PrivacyPolicy
