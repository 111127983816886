import React from 'react';
import { Link } from 'react-router-dom';

const FooterNav = ({ FooterNavMenus, setToTop }) => {
  return (
      <ul className="list-unstyled">
          {
              FooterNavMenus.map((data, i) => (
                  <li key={i}><Link onClick={(e) => setToTop(e, data)}>{data.Name}</Link></li>
              ))}
      </ul>
  )
}

export default FooterNav