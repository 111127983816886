import React from 'react'
import Footer from '../layouts/Footer';
import Header from '../layouts/Header';
import Content from '../sections/Footer/PrivacyPolicy';
import TopNavigation from '../layouts/TopNavigation';

const PolicyPage = () => {
  return (
    <div>
      <Header/>
      <TopNavigation/>
      <Content/>
      <Footer/>
    </div>
  )
}

export default PolicyPage
