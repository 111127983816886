import axios from 'axios';
import BaseUrl from '../modules/config/BaseUrl';

export const getAllWeAreIcon = () => {
    return axios({
        method: 'GET',
        url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllQualitiesUserImages`
    });
};

export const getAllWeAreContent = () => {
    return axios({
        method: 'GET',
        url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetQualitiesContentParagraph`
    });
};