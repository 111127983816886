import React from 'react'
import { Route, Routes, Navigate } from "react-router-dom";
import Home from '../pages/Home';
import AboutUs from '../pages/AboutUs';
import ContactUs from '../pages/ContactUs';
import Downloads from '../pages/Downloads';
import NewsAndInsights from '../pages/NewsAndInsights';
import OurTeam from '../pages/OurTeam';
import Products from '../pages/Products';
import '../../assets/css/style.css';
import '../../assets/css/responsive.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import NewsHistory from '../sections/Home/NewsAndInsights/NewsHistory';
import PrivacyPolicy from '../pages/PolicyPage';
import RulesAndRegulations from '../pages/RulesPage';
import Disclaimer from '../pages/Disclaimer';
// import NewsItem from '../sections/NewsAndInsights/NewsItem';
// import ProductPage from '../sections/Products/ProductPage';
// import ScrollToTop from './ScrollToTop';

const Content = () => {
  return (
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/AboutUs' element={<AboutUs/>} />
      <Route path='/ContactUs' element={<ContactUs/>} />
      <Route path='/Downloads' element={<Downloads/>} />
      <Route path='/NewsAndInsights' element={<NewsAndInsights/>} />
      {/* <Route path='/NewsAndInsights/:id' element={<NewsItem/>}/> */}
      <Route path='/OurTeam' element={<OurTeam/>} />
      <Route path='/Products' element={<Products />} />
      {/* <Route path='/Products/:id' element={<ProductPage />} /> */}
      <Route path='/NewsHistory' element={<NewsHistory />} />
      <Route path="/Disclaimer" element={<Disclaimer />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/RulesAndRegulations" element={<RulesAndRegulations />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default Content
