import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';




export default function FooterBrowserCompatibility() {

    const [show, setShow] = useState(false);
    const [play, setPlay] = useState(true)
    const headerStyle = {
        cursor: "pointer",
        display: "inline-block"
    }



    return (
        <>
            <div style={headerStyle} onClick={(e) => (e.preventDefault(), setShow(true), setPlay(false))}>BROWSER COMPATIBILITY</div>
            <Modal id="myModal" isOpen={show} toggle={() => setShow(!show)} size='md' centered>
                <ModalHeader className="border-0" toggle={() => (setShow(!show), setPlay(true))} >
                    <div className="border-0 home-modal modal-ui-hd">
                        <div className="text-red">BROWSER COMPATIBILITY</div>
                    </div>
                </ModalHeader>
                <ModalBody className='modal-ui '>
                    <h5>DESKTOP</h5>
                    <hr />
                    <div> Google Chrome: 71 or higher</div>
                    <div>Microsoft Edge: 100 or higher</div>
                    <div>Safari: Monterey or higher</div>
                    <br />
                    <h5>MOBILE</h5>
                    <hr />
                    <div>Android: 8.0 or higher</div>
                    <div>iOS: 13.0 or higher</div>
                </ModalBody>
            </Modal>
        </>
    )
}
