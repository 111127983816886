import React, { useEffect, useState } from "react";
import parse, { Element } from "html-react-parser";
import BannerComponent from "../../common/Banner/BannerComponent";
import OurTeamParagraph from "./OurTeamParagraph";
import OurTeamTestimonials from "./OurTeamTestimonials";
import Loader from '../../common/Loader/Loader';
import { usePageDataStore } from "../../zustand/pageDataStore";
import { Helmet } from 'react-helmet';

const OurTeam = ({setNewLoading,newLoading}) => {
  const {pageData,GET_PAGE_ID,QUERY_PAGE_ID} = usePageDataStore(state=>state);
  
  const [loader1,setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [pageId,setPageId] = useState('');

  const parser = (input) =>
    parse(input, {
      replace: (domNode) => {
        if (domNode instanceof Element && domNode.attribs.class === "remove") {
          return <></>;
        }
      },
    });
    useEffect(() => {
        //get a page id from zustand store
      const pageCall = async()=>{
        if(pageData?.length < 1){
          await QUERY_PAGE_ID();
        }
       const Id = GET_PAGE_ID("Our Team");
       setPageId(Id.pageId)
      }
      pageCall();
    // eslint-disable-next-line
    }, [])
  
  return (
    <>
      {/* {[loader1, loader2, loader3].includes(true) && <Loader />} */}
      <Helmet>
        <title>Our Team | Nippon Life India Asset Management (Singapore)</title>
        <meta name="description" content="Read about the team members at Nippon Life India Asset Management (Singapore). Click to learn more about us & invest in mutual funds online today!" />
        <meta name="keywords" content="nippon life India asset management Singapore team, team of  nippon life India asset management Singapore" />
      </Helmet>
      <BannerComponent  imageAlt={`OurTeamBanner`} pageId={pageId} setLoader1={setLoader1} setNewLoading={setNewLoading}/>
      {newLoading && (
        <>
      <OurTeamParagraph parser={parser} setLoader2={setLoader2} />
      <OurTeamTestimonials parser={parser} setLoader3={setLoader3} imageAlt={`OurTeamItem`} />
       </>
      )}
    </>
  );
};

export default OurTeam;
