import axios from 'axios';
import BaseUrl from '../modules/config/BaseUrl';


export const GetFooterContentParagraph = () => {
    return axios({
        method: 'GET',
        url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetFooterContentParagraph`
    });
};

export const GetAllFooterDisclaimer = (data) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllUserFooterDisclaimer`,
	});
}

export const GetAllFooterPrivacyPolicy = (data) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllUserFooterPrivacyPolicy`,
	});
}

export const GetAllRulesRegulations = (data) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllUserRulesRegulations`,
	});
}

export const GetAllFooterIconAndDescriptions = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllFooterIconAndDescriptions`,
	});
}

export const GetAllFooterContactUsDetails = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllFooterContactUsDetails`,
	});
}

export const GetAllFooterContactUsAddressDetails = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllFooterContactUsAddressDetails`,
	});
}

export const GetAllFooterContactUsSocialIconDetails = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllFooterContactUsSocialIconDetails`,
	});
}

export const GetAllFooterContactUsSocialUserIconDetails = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllFooterContactUsSocialUserIconDetails`,
	});
}

// export const GetFooterContactUsSocialIconDetails = () => {
// 	return axios({
// 		method: 'GET',
// 		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetFooterContactUsSocialIconDetails?FooterContactUsSocialId=1`,
// 	});
// }

export const GetAllFooterCopyright = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllFooterCopyright`,
	});
}

