import React from 'react'
import SETTING from '../../../modules/config/settings';

const OutlookItems = ({ item }) => {


    return (
        <><div className="col-lg-12 col-md-12 col-12 px-2" >
            <div className="outlook-box bg-lightgrey">
                <div className="outlook-box-img">
                    {item.newsInsightsButtonCode === 2 ? <a target='_' onClick={(event) => { event.preventDefault(); window.open(`${SETTING.IMAGE_API_URL_NEWS}${item.outlookPdfName}`) }}><img src={`${SETTING.IMAGE_API_URL_NEWS}${item.outlookIcon}`} alt='newsImg' className="img-fluid" /></a> : <a target='_' href={item.newsInsightButtonLink}><img src={`${SETTING.IMAGE_API_URL_NEWS}${item.outlookIcon}`} alt='newsImg' className="img-fluid" /></a>}
                    
                </div>
                <div className="outlook-box-content">
                    <h3>{item.newsInsightsButtonCode === 2 ? <a target='_' onClick={(event) => { event.preventDefault(); window.open(`${SETTING.IMAGE_API_URL_NEWS}${item.outlookPdfName}`) }}>{item.outlookJoinTitle}</a> : <a target='_' href={item.newsInsightButtonLink}>{item.outlookJoinTitle}</a>}</h3>
                    <p>{item.newsInsightsButtonCode === 2 ? <a target='_' onClick={(event) => { event.preventDefault(); window.open(`${SETTING.IMAGE_API_URL_NEWS}${item.outlookPdfName}`) }}>{item.newsInsightDescription}</a> : <a target='_' href={item.newsInsightButtonLink}>{item.newsInsightDescription}</a>}</p>
                    <div className="btn-singapore">
                        {item.newsInsightsButtonCode === 2 ? <a target='_' onClick={(event) => { event.preventDefault(); window.open(`${SETTING.IMAGE_API_URL_NEWS}${item.outlookPdfName}`) }}>{item.newsInsightButtonName}</a> : <a target='_blank' href={item.newsInsightButtonLink}>{item.newsInsightButtonName}</a>}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default OutlookItems