import React, { useState } from 'react'
import Swal from 'sweetalert2';
import editIcon from "../../../assets/images/icons/edit.svg"
import ReCAPTCHA from "react-google-recaptcha";
import { postContactUs } from '../../../api/ContactUs';

const ContactUsForm = ({setLoader3}) => {
    const [user, setUser] = useState({
        name: "",
        mobile: "",
        mail: "",
        comments: "",
    });
    const [error, setError] = useState({});
	const [isverified, setIsverified] = useState(false);
    const [loading, setLoading] = useState(false);

	const grecaptchaObject = window.grecaptcha;

    const onChangeCaptcha = (e) => {
        if (e !== null) {
			setIsverified(true);
			setError({ ...error, ischkd:"" });
		}
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        if(name === "comments" && e.target.value.length >= 251){ 
            setTimeout(() => {
                setError({ [name] : "Comments shouldn't exceed 250 characters" })
            }, 100);
        }
        // if(name === "mobile" && /^-?\d*$/.test(value) === false){ 
        //     setTimeout(() => {
        //         setError({ [name] : "Please enter numbers only" })
        //     }, 100);
        // }
        // if (name === "name" && /^[a-zA-Z]*$/.test(value) === false){ 
        //     setTimeout(() => {
        //         setError({ [name]: "Please enter a valid name" })
        //     }, 100);
        // }
        setUser({ ...user, [name]: value });
        setError({ ...error, [name]: "" });
    };

    const handleClear = () => {
        setUser({
            name: "",
            mobile: "",
            mail: "",
            comments: "",
        })
        setLoading(false);
        window.grecaptcha.reset();
    }

    const errorHandler = () => {
        let error = {};
        let isValid = true;
        let alphaRegx = /^[aA-zZ\s]+$/
        const phone = /^[0-9]{10,15}$/
        const mailPattern = /^[a-z0-9._-]+@[a-z0-9.]+\.[a-z]{2,}$/
        if (user.name === '') {
            isValid = false;
            error["name"] = "Please enter name";
        }
        if (alphaRegx.test(user.name) === false) {
            isValid = false;
            error["name"] = "Please enter a valid name";
        }
        if (user.mobile === '') {
            isValid = false;
            error["mobile"] = "Please enter mobile number";
        } else if (/^-?\d*$/.test(user.mobile) === false){
            isValid = false;
            error["mobile"] = "Please enter only numbers";
        }
        // else if (phone.test(user.mobile) === false){
        //     isValid = false;
        //     error["mobile"] = "Please enter 10 digit mobile number"
        // }
         else if (phone.test(user.mobile) === false && user.mobile.length < 10){
            isValid = false;
            error["mobile"] = "Mobile number must be equal to 10 digits or greater"
        } else if (phone.test(user.mobile) === false && user.mobile.length > 15){
            isValid = false;
            error["mobile"] = "Mobile number must be equal to 15 digits or lesser"
        }
        if (user.mail === '') {
            isValid = false;
            error["mail"] = "Please enter email";
        } else if (mailPattern.test(user.mail) === false){
            isValid = false;
            error["mail"] = "Please enter valid email";
        }
        if (user.comments === '') {
            isValid = false;
            error["comments"] = "Please enter comments";
        } else if (user.comments.length >= 251) {
            isValid = false;
            error["comments"] = "Please enter comments within 250 characters";
        }
        if (isverified !== true) {
			error['ischkd'] = 'Please click on the captcha field.';
			isValid = false;
		}
        setError(error);
        return isValid;
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        const isValid = errorHandler();
        if (isValid) {
            const data = {
                "contactUsName": user.name,
                "contactUsMobile": user.mobile,
                "contactUsEmail": user.mail,
                "contactUsComments": user.comments
            }
            setLoader3(true);
           await postContactUs(data)
            .then((result) => {
                if(result.status === 200){
                    setLoader3(false);
                    if(result.data.response.status === 1){
                        Swal.fire({ text: `${result.data.response.message}` });
                        handleClear();
                        setLoader3(false);
                    }
                }
            })
            .catch((error) => {
                console.log(error, "error in submitting");
                setLoader3(false);
            })
        }
    }

    const BouncingDotsLoader = () => {
        return (
            <div className="bouncing-loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
        );
    };
    
    return (
        <div className="col-lg-5 col-md-5 col-12">
            <div className="contact-us-form">
                <div className="address-sec">
                    <div className="address-sec-icon">
                        <img src={editIcon} alt='edit' className="img-fluid" />
                    </div>
                    <div className="address-sec-text">
                        <h4 className="text-red">Write to us</h4>
                    </div>
                </div>
                <form>
                    <div className="form-group">
                        <input type="text" name="name"  className="form-control" placeholder="Name" value={user.name} onChange={handleChange} />
                        {error ? (<div className="mt-2" style={{ fontSize: 12, color: "red" }}> {error.name}</div>) : null}
                    </div>
                    <div className="form-group">
                        <input type="number" name="mobile" className="form-control" placeholder="Mobile" value={user.mobile} onChange={handleChange} />
                        {error ? (<div className="mt-2" style={{ fontSize: 12, color: "red" }}> {error.mobile}</div>) : null}
                    </div>
                    <div className="form-group">
                        <input type="email" name="mail" className="form-control" placeholder="Email" value={user.mail} onChange={handleChange} />
                        {error ? (<div className="mt-2" style={{ fontSize: 12, color: "red" }}> {error.mail}</div>) : null}
                    </div>
                    <div className="form-group">
                        <textarea className="form-control" name="comments" placeholder="Comments" rows="3" value={user.comments} onChange={handleChange} maxLength={251}></textarea>
                        {error ? (<div className="mt-2" style={{ fontSize: 12, color: "red" }}> {error.comments}</div>) : null}
                    </div>
                    <ReCAPTCHA
                        sitekey="6LefDCApAAAAAIQoZtckDxHhaC5v21bOU7eLJ1RL"
                        onChange={onChangeCaptcha}
                        grecaptcha={grecaptchaObject}
                        data-type="image"
                        className='captcha'
                        data-theme="dark"
                        onExpired={() => setIsverified(false)}
                    />
                    {error ? (<div className="mt-2" style={{ fontSize: 12, color: "red" }}> {error.ischkd}</div>) : null}
                    <div className="form-group">
                        {loading ? <div className='btn btn-submit-load'><BouncingDotsLoader/></div> :
                        <button className="btn btn-submit" onClick={handleSubmit}>Submit</button>}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ContactUsForm
