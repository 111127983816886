import React,{useEffect, useState} from 'react'
import parse, { Element } from "html-react-parser";
import DownloadsParagraph from './DownloadsParagraph';
import ModalComponent from './ModalComponent';
import { useNavigate } from "react-router-dom";
import { getAllFooterDownloadDisclaimer } from '../../../api/DownloadApi';
import { usePageDataStore } from '../../zustand/pageDataStore';
import { useSessionStore } from '../../zustand/sessionData';
import Loader from '../../common/Loader/Loader';
// import DownloadsNew from './DownloadsNew';
import BannerComponent from '../../common/Banner/BannerComponent';
import DownloadDocuments from './DownloadDocuments';

const Downloads = ({setNewLoading,newLoading}) => {
  const {pageData,GET_PAGE_ID,QUERY_PAGE_ID} = usePageDataStore(state=>state);
  const {showDownloadModal,SET_SHOW_DOWNLOAD_MODAL} = useSessionStore(state=>state);

  const navigate = useNavigate();
  const [show,setShow] = useState(false);
  const [pageId,setPageId] = useState('');
  const [loader1,setLoader1] = useState(false);
  const [loader2,setLoader2] = useState(false);
  const [loader3,setLoader3] = useState(false);
  const [loader4,setLoader4] = useState(false);
  const [disclaimer, setDisclaimer] = useState({})

  useEffect(() => {
    setShow(showDownloadModal)
  }, [showDownloadModal])

  useEffect(() => {
    getDisclaimer();
      //get a page id from zustand store
    const pageCall = async () => {
      if (pageData?.length < 1) {
        await QUERY_PAGE_ID();
      }
      const Id = GET_PAGE_ID("Downloads");
      setPageId(Id.pageId)
    }
    pageCall();
    // eslint-disable-next-line
  }, [showDownloadModal])

  const parser = (input) =>
    parse(input, {
      replace: (domNode) => {
        if (domNode instanceof Element && domNode.attribs.class === "remove") {
          return <></>;
        }
      },
    });
  
  const handleDisagree = ()=>{
      navigate('/')
      setShow(false)
  }

  const getDisclaimer = async () => {
    try {
        setLoader2(true);
        const { data, status } = await getAllFooterDownloadDisclaimer();
  
        //Table data
        if (status === 200) {
          if (data.response.status === 1) {
            setDisclaimer(data.response.data[0]);
              setShow(showDownloadModal);
            } else {
                setDisclaimer({});
            }
        } else {
            setDisclaimer({});
        }
        setLoader2(false);
    } catch (e) {
      setLoader2(false);
      console.log(e, "Error in the Downloads Disclaimer");
    }
}

  return (
    <>
      {/* {[loader1, loader2, loader3, loader4].includes(true) && <Loader />} */}
      <ModalComponent data={disclaimer} show={show} handleDisagree={handleDisagree} handleClose={() => { SET_SHOW_DOWNLOAD_MODAL(false) }} setShow={setShow} />
      <BannerComponent imageAlt={`DownloadsBanner`} pageId={pageId} setLoader1={setLoader1} setNewLoading={setNewLoading}/>
      {newLoading &&
      <DownloadsParagraph show={show} parser={parser} setLoader3={setLoader3} />
      }
      {show === false && <DownloadDocuments setLoader4={setLoader4} /> }
    </>
  )
}

export default Downloads