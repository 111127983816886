import React, { useEffect } from 'react';
import { GetAllFooterContactUsAddressDetails } from "../../../api/FooterApi";
import { useFooterDataStore } from '../../zustand/footerDataStore';
import locationImg from "../../../assets/images/location.png";
import { FooterImage } from '../../common/Footer/FooterImage';





const FooterLocation = ({setLoader2}) => {

    const { locationAddressData, setLocationAddressApiData } = useFooterDataStore(state => state);

    useEffect(() => {
        if (locationAddressData.length === 0) getAllFooterContactUsAddressDetails();
    }, [])


    const getAllFooterContactUsAddressDetails = async () => {
        try {
            setLoader2(true);
            const { data, status } = await GetAllFooterContactUsAddressDetails();
            if (status === 200) {
                setLoader2(false);
                if (data?.response?.status === 1) {
                    setLocationAddressApiData(data?.response?.data?.contactUsAddressDetails)
                } else {
                    setLocationAddressApiData([])
                }
            }
            else {
                setLocationAddressApiData([])
            }

        } catch (e) {
            setLoader2(false);
            console.log(e, "Error in Footer Location Address");
        }
    }

    
    return (

        <>
            {locationAddressData?.map((item, id) => <div className='footer-location' key={id}>
                <p className="mb-3 no-select"
                    onClick={() => window.open(item.contactUsAddressLink, 'blank')}
                >{item.contactUsAddressText}</p>
                </div>)}
        </>

    )
}

export default FooterLocation