import React, { useState, useEffect } from "react";
import { getOurTeamDescription } from "../../../api/OurTeamApi";

const OurTeamParagraph = ({ parser,setLoader2 }) => {

  const [data, setData] = useState("");

  useEffect(() => {
    GetDescription();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const GetDescription = async () => {
    try {
      setLoader2(true);
      const { data, status } = await getOurTeamDescription();
      if (status === 200) {
        if (data?.response?.status === 1) {
          setData(data?.response?.data?.teamContentParagraph);

        } else {
          setData("");
        }
      }
      else {
        setData("");
      }
      setLoader2(false);

    } catch (e) {
      setLoader2(false);
      console.log(e, "Error in Our Team description");
    }
  }
  return (
    <>
      <section className="section-padding bg-lightgreyy">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                <h1>
                  Our <span className="text-red">Team</span>
                </h1>
              </div>
            </div>
            <div className="col-md-10 col-12 mx-auto about-para">{parser(data)}</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurTeamParagraph;
