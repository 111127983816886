import React, { useEffect, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getSectionByIdnNewsAndInsightDetails } from '../../../../api/NewsAndInsightApi';
import OutlookItems from '../../NewsAndInsights/OutlookItems';
import "react-multi-carousel/lib/styles.css";
import { Button } from 'reactstrap';
import {useNavigate} from 'react-router-dom'



const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    slidesToSlide: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

const NewsAndInisghtsNew = ({ setLoader6 ,setToTop}) => {
  const [newsData, setNewsData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAllNews()
  }, [])

  const getAllNews = async () => {

    setLoader6(true);
    await getSectionByIdnNewsAndInsightDetails(2).then(res => {
      if (res.status === 200) {
        if (res.data.response.status === 1) {
          let outlookNews = res.data.response.data.newsInsightsIconsDetails[0].newsInsightsItems;
          setNewsData(outlookNews);
        }
      }

    }).catch(e => {
      setLoader6(false);
      console.log(e, "Error in the News & Insights section from Homepage");
    }).finally(e => {
      setLoader6(false);
    });
  }

  // const navigate = useNavigate();

  // const toNewsItem = (e, item) => {
  //     e.preventDefault();
  //     navigate(`/NewsAndInsights/${item.newsId}`, { state: item });
  // }

  const newsHistory = () => {
      navigate("/NewsHistory")
  }

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="carousel-button-group">
        <Button className={currentSlide === 0 ? ' owl-prev disable' : 'owl-prev'} onClick={() => previous()} />
        <Button className="owl-next" onClick={() => next()} />

      </div>
    );
  };
 

  return (
    <>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div id="newsandinsighthd" className="section-title text-center">
                <h1 style={{ cursor: "pointer" }} onClick={(e) => setToTop(e, "/NewsAndInsights")}>News and <span className="text-red">Insights</span></h1>
              </div>
            </div>
          </div>
          <div className="outlook react-multi col-12">
            <Carousel responsive={responsive} draggable={false} arrows={false} showDots={false} renderButtonGroupOutside={true} infinite={true}
              autoPlay={true}
              autoPlaySpeed={7000}
              customTransition="all 1000ms" transitionDuration="1000"
              customButtonGroup={<ButtonGroup />}
              keyBoardControl={true} dotListClass="custom-dot-list-style">
              {newsData && newsData.map(item => <OutlookItems key={item.outlookNewsId} item={item} />)}

            </Carousel>

          </div>
         
          </div>
          <br />
                    <div style={{ textAlign: "center" }} className="form-group">
                        <button onClick={() => newsHistory()} className="btn btn-submit">Historic News</button>
                    </div>
      </section>
    </>
  )
}

export default NewsAndInisghtsNew