import React,{useEffect} from 'react'
import { getDownloadDescription } from '../../../api/DownloadApi';

const DownloadsParagraph = ({ parser, show ,setLoader3}) => {

    const [paraData, setParaData] = React.useState('');

    useEffect(() => {
        show === false && GetDescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    const GetDescription = async () => {
        try {
            setLoader3(true);
            const { data, status } = await getDownloadDescription();
            if (status === 200) {
                if (data?.response?.status === 1) {
                    setParaData(data?.response?.data?.downloadContentParagraph);
                } else {
                    setParaData("");
                }
            }
            else {
                setParaData("");
            }
            setLoader3(false);

        } catch (e) {
            setLoader3(false);
            console.log(e, "Error in the Downloads description");
        }
    }

    return (
        <>
            <section className="section-padding bg-lightgrey">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-12 mx-auto">
                            <div className="text-center section-title">
                                <h1>Downloads</h1>
                            </div>
                            <div className="about-para">{paraData && parser(paraData)}</div>
                        </div>
                    </div>
                </div>
            </section>
        </>)
}

export default DownloadsParagraph