import React, { useEffect ,useState} from 'react';
import parse from 'html-react-parser';
import { getAboutUsDescription } from '../../../../api/AboutUsApi'
import { Link } from 'react-router-dom';

const AboutUs = ({ setLoader2, setToTop }) => {

  const [data, setData] = useState('');
 
  useEffect(() => {
    GetDescription();
  }, [])

  const GetDescription = async()=> {
    try {
      setLoader2(true);
      const { data, status } = await getAboutUsDescription();
      if (status === 200) {
        if (data.response.status === 1) {
          setData(data.response.data.aboutUsHomeContentParagraph);
        } else {
          setData("");
        }
      }
      else {
        setData("");
      }
      setLoader2(false);

    } catch (e) {
      setLoader2(false);
      console.log(e, "Error in About Us description from Homepage");
    }
  }

  return (
    <>
      <section className="section-padding bg-lightgreyy home-about-us">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                <h1>About <span className="text-red">Us</span></h1>
              </div>
            </div>
            <div className="col-md-10 col-12 mx-auto">
              {data ? <>
              <div className="about-para">
              {data &&  parse(data)}
              </div>
              <div className="btn-singapore">
                  <Link onClick={(e) => setToTop(e, "/AboutUs")} >Read More</Link>
              </div>
              </>
                : null}
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default AboutUs