import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import SETTING from '../../../modules/config/settings';
import { getContactUsAllDetails, getContactUsAddress, getAllContactSocials } from "../../../api/ContactUs"
import locationIcon from "../../../assets/images/icons/location.svg"
import letterIcon from "../../../assets/images/icons/letter.svg"
import phoneIcon from "../../../assets/images/icons/phone.svg"

const ContactUsDetails = ({setLoader2}) => {

    useEffect(() => {
        getLocation();
        getDetails();
        getSocials()
        // eslint-disable-next-line
    }, []);

    const [contactUsDetails, setContactUsDetails] = useState([]);
    const [contactUsLocation, setContactUsLocation] = useState([]);
    const [contactUsSocials, setContactUsSocials] = useState([]);

    const getDetails = async () => {
        try {
            setLoader2(true);
            const { data, status } = await getContactUsAllDetails();
            if (status === 200) {
                if (data.response.status === 1) {
                    setContactUsDetails(data.response.data.contactUsDetails);

                } else {
                    setContactUsDetails([]);
                }
            }
            else {
                setContactUsDetails([]);
            }
            setLoader2(false);

        } catch (e) {
            setLoader2(false);
            console.log(e, "Error in the Contact Us Details");
        }

    }

    const getLocation = async () => {
        try {
            setLoader2(true);
            const { data, status } = await getContactUsAddress();
            if (status === 200) {
                if (data.response.status === 1) {
                    setContactUsLocation(data.response.data.contactUsAddressDetails);

                } else {
                    setContactUsLocation([]);
                }
            }
            else {
                setContactUsLocation([]);
            }
            setLoader2(false);

        } catch (e) {
            setLoader2(false);
            console.log(e, "Error in the Contact Us Location");
        }
    }

    const getSocials = async () => {
        try {
            setLoader2(true);
            const { data, status } = await getAllContactSocials();
            if (status === 200) {
                if (data.response.status === 1) {
                    setContactUsSocials(data.response.data.contactUsSocialIconsDetails);

                } else {
                    setContactUsSocials([]);
                }
            }
            else {
                setContactUsSocials([]);
            }
            setLoader2(false);

        } catch (e) {
            setLoader2(false);
            console.log(e, "Error in the Contact Us Socials");
        }
    }


  return (
      <div className="col-lg-5 col-md-7 col-12 border-right">
          {/* eslint-disable-next-line */}
          {contactUsLocation.length != 0 ? <div className="address-sec mb-4">
              <div className="address-sec-icon no-select address-pointer" onClick={() => window.open(contactUsLocation[0]?.contactUsAddressLink, 'blank')}>
                  <img src={locationIcon} alt='location' className="img-fluid" />
              </div>
              <div className="address-sec-text address-pointer" onClick={() => window.open(contactUsLocation[0]?.contactUsAddressLink, 'blank')} >
                  <h4 className="text-red no-select">Find us</h4>
                  {contactUsLocation?.map((item, id) => <p className='no-select' key={id} >{item.contactUsAddressText}</p>)}
              </div>
          </div>
              : null}
          {contactUsDetails?.map((item, id) => <div key={id}>
              <div className="address-sec no-select">
                  <div className="address-sec-icon">
                  </div>
                  <div className="address-sec-text no-select">
                      <h4 className="text-red">{item.contactUsName}</h4>
                  </div>
              </div>
              {/* eslint-disable-next-line */}
              {item.contactUsEmailId != "" ?
                  <div className="address-sec">
                      <div className="address-sec-icon no-select">
                          <img src={letterIcon} alt='letter' className="img-fluid" />
                      </div>
                      <div className="address-sec-text no-select">
                          <p>{item.contactUsEmailId}</p>
                      </div>
                  </div> : null}
              {/* eslint-disable-next-line */}
              {item.contactUsPhoneNo != "" ?
                  <div className="address-sec mb-4 no-select">
                      <div className="address-sec-icon">

                          <img src={phoneIcon} alt='phone' className="img-fluid" />
                      </div>
                      <div className="address-sec-text no-select">
                          <p>{item.contactUsPhoneNo}</p>
                      </div>
                  </div> : null}
          </div>)}
          <div className="social-link-contact mt-4">
              <ul className="list-inline">
                  {contactUsSocials?.map(item => <li key={item.contactUsSocialId} className="list-inline-item">
                          <Link onClick={() => window.open(item.contactUsSocialIconLink, 'blank')}>
                              <img src={SETTING.IMAGE_API_URL_CONTACT_US + item.contactUsSocialIconImage} alt={item.contactUsSocialIconName} className="img-fluid" />
                          </Link>
                </li>)}
              </ul>
          </div>
      </div>
  )
}

export default ContactUsDetails