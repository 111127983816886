import axios from "axios";
import BaseUrl from '../modules/config/BaseUrl';

export const getDownloadDescription = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/DownloadsUser/GetDownloadsContentParagraph`,
	});
};

export const getAllDownloadDocuments = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/DownloadsUser/UserGetDownloadFilesById`,
	});
};

export const getAllFooterDownloadDisclaimer = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllFooterDownloadDisclaimer`
	});
};
