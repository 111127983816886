import React from 'react'
import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser';
import { Modal, ModalHeader, ModalBody,ModalFooter } from 'reactstrap';
function ModalComponent({ data , show, handleClose, handleDisagree,setShow }) {

  // setTimeout(() => {
  //   setLoader3(false)
  // }, 1000)

  return (
    <>
      <Modal
        isOpen={show}
        toggle={handleClose}
        backdrop="static"
        keyboard={false}
        size='lg'
      >
        <ModalHeader toggle={handleDisagree}  >
          <div>
 {data.footerContentHeading ? parse(data.footerContentHeading) : ""}
          </div>
           
        </ModalHeader>
        <ModalBody>
          {data.footerContentParagraph ? parse(data.footerContentParagraph) : ""}
        </ModalBody>
        <ModalFooter>
          <Button variant="danger" onClick={handleDisagree}>
            DISAGREE
          </Button>
          <Button variant="primary" onClick={handleClose}>AGREE</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalComponent