import React, { useEffect, useState } from 'react';
import { GetAllFooterDisclaimer } from "../../../api/FooterApi";
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import Loader from '../../common/Loader/Loader';

const FooterDisclaimer = () => {

    const [disclaimerContentDescription, setdisclaimerContentDescription] = useState('');
    const [disclaimerContentTitle, setdisclaimerContentTitle] = useState('');

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getFooterDisclaimer();
    }, [])
    

    const getFooterDisclaimer = async () => {
        try {
          setLoader(true);
          const { data, status } = await GetAllFooterDisclaimer();
          if (status === 200) {
            setLoader(false);
            if (data?.response?.status === 1) {
              setdisclaimerContentDescription(data?.response?.data[0].footerContentParagraph);
              setdisclaimerContentTitle(data?.response?.data[0].footerContentHeading)
            } else {
              setdisclaimerContentDescription('');
              setdisclaimerContentTitle('')
            }
          }
          else {
            setdisclaimerContentDescription('');
            setdisclaimerContentTitle('')

          }
      
        } catch (error) {
          console.log(error);
          setLoader(false);
        }
      }

  return (
    <>
    {/* {loader && <Loader />} */}
      <Helmet>
        <title>Disclaimer | Nippon Life India Asset Management (Singapore)</title>
        <meta name="description" content="Disclaimer: We at Nippon Life India Asset Management (Singapore) respect investors/customers/clients' right to privacy. Click here to read our disclaimer!" />
        <meta name="keywords" content="nippon life India asset management Singapore disclaimer, nippon Singapore disclaimer" />
      </Helmet>
     <section className="section-padding our-team-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                {parse(disclaimerContentTitle)}
              </div>
            </div>
            <div className="col-12">
            <div>{parse(disclaimerContentDescription)}</div>
            </div>
          </div>
        </div>
      </section>
        <div></div>
        
    </>
  )
}

export default FooterDisclaimer