import React, { useState } from 'react'
import Footer from '../layouts/Footer';
import Header from '../layouts/Header';
import Content from '../sections/Downloads/Downloads';
import TopNavigation from '../layouts/TopNavigation';

const Downloads = () => {
  const [newLoading, setNewLoading] = useState(false)
  return (
    <div>
      <Header/>
      <TopNavigation/>
      <Content setNewLoading={setNewLoading} newLoading={newLoading}/>
      {newLoading && <Footer/>}
    </div>
  )
}

export default Downloads
