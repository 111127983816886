import React, { useEffect, useState } from "react";
import { getOurTeamData } from "../../../api/CeoOurTeamApi";
import DummyTeam from "../../../assets/images/teams/dummy.jpg"

const OurTeamTestimonials = ({ imageAlt, setLoader3, parser }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    GetOurTeamDetails();
  }, [])

  const GetOurTeamDetails = async() => {

    try {
      setLoader3(true);
      const { data, status } = await getOurTeamData();
      if (status === 200) {
        if (data.response.status === 1) {
          let ourTeamData = await data.response.data
          setData(ourTeamData)
        } else {
          setData([]);
        }
      }
      else {
        setData([]);
      }
      setLoader3(false);

    } catch (e) {
      setLoader3(false);
      console.log(e, "Error in the Downloads description");
    }
  }
  

  return (
    <>
      <section className="section-padding">
        <div className="container">
          {data?.map(item => (
            <div
              key={item.ourTeamId}
              className="row justify-content-center g-0 teams-mb"
              id="abhijit-singh"
            >
              <div className="col-lg-6 col-md-7 col-12 order-2 order-md-1">
                <div className="team-description bg-lightgrey">
                  <div className="team-description-name">
                    <h4 className="text-red">{item?.directorName}</h4>
                    <div>
                      {parser(item?.summary)}
                    </div>
                  </div>
                  <div className="team-description-para">
                    <div className="para">{parser(item?.description)}</div>
                 
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 col-12 align-self-center order-1 order-md-2">
                <div className="team-img">
                  <img src={item?.imageUrl !== "" ? item?.imageUrl : DummyTeam} alt='teamImg' className="img-fluid" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default OurTeamTestimonials;
