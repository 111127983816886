import BaseUrl from '../modules/config/BaseUrl';
import axios from 'axios';

export const getAboutUsDescription = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAboutUsContentParagraph`,
	});
};

export const getAllAboutUsIconAndDescription = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllAboutUsIconAndDescriptions`,

	});
}