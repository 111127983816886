import React, { useEffect, useRef } from 'react';
import { useSessionStore } from '../../zustand/sessionData';

const SessionTimeout = () => {
    const {sessionTimeoutDuration,SET_SHOW_DOWNLOAD_MODAL} = useSessionStore(state=>state);

  let sessionTimer = useRef(null);

  const resetSessionTimer = () => {
    clearTimeout(sessionTimer.current);
    sessionTimer.current = setTimeout(() => {
        SET_SHOW_DOWNLOAD_MODAL(true);
      console.log('Session timeout');
    }, sessionTimeoutDuration);
  };

  const handleUserInteraction = () => {
    resetSessionTimer();
  };

  useEffect(() => {
    resetSessionTimer();

    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('keydown', handleUserInteraction);

    return () => {
      clearTimeout(sessionTimer.current);
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default SessionTimeout;
