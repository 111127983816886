import React, { useEffect, useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ReactOwlCarousel from 'react-owl-carousel';
import SETTING from '../../../modules/config/settings';
import { getBannerImagesByPageId } from '../../../api/BannerApi';
import SkeletonLoader from '../Skeleton/SkeletonLoader';
import { Fade } from 'reactstrap';

const MultipleBanner = ({imageAlt,pageId,setLoader1,setNewLoading}) => {
    const [bannerData,setBannerData] = useState([]);
    useEffect(() => {
        pageId && getBannerTableData(pageId)
    }, [pageId])
    
    const getBannerTableData = async (id) => {
        setLoader1(true);
        try {
            const { data, status } = await getBannerImagesByPageId(id);
            const res = data.response;
            if (status === 200) {
                if (res.status === 1) {
                    setBannerData(res.data);
                } else {
                    setBannerData([]);
                }
            } else {
                setBannerData([]);
            }
            setLoader1(false);
        } catch (e) {
            setLoader1(false);
            console.log(e,"Error in the Multi Banners")
        }
    }

    const options = {
        loop: true,
        items: 1,
        autoplay: true,
        dots: true,
        autoplayTimeout: 6000,
        smartSpeed: 900,
        mouseDrag:false
        // animateOut: 'fadeOut'
    };
    const handleImageLoad = () => {
        setNewLoading(true);
    };
    
    return (
        <section className="banner-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-0">
                    {bannerData.length < 1 ? ( <SkeletonLoader variant="rectangular" width='100%' height={300} />) :
                            (<ReactOwlCarousel id="pageBanner" className='owl-theme lg-12'  {...options}>
                                {bannerData.map((val, i) => <img  src={SETTING.BANNER_IMAGE_API_URL + val.bannerImage} className="img-fluid" alt={imageAlt} key={i} onLoad={handleImageLoad}/>)}
                        </ReactOwlCarousel>
                        )
                    }
                    </div>
                </div>
            </div>
        </section>

    )
}

export default MultipleBanner