import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import SessionTimeout from '../common/Session/SessionTimeout'
const Navbar = () => {
  const [toggle, setToggle] = useState(false)
  const handleToggle = () => {
    setToggle(true)
  }
  return (
	<>
	<SessionTimeout/>
    <div className="menu-singapore">
 		<div className="container">
 			<div className="row">
 				<div className="col-12"> 
 					<nav className="navbar navbar-expand-lg justify-content-center">
					  <div className="container-fluid">
					    <button className= {toggle === false ? "navbar-toggler ms-auto" : "navbar-toggler ms-auto collapsed"} type="button" data-bs-toggle="collapse" data-bs-target="#singaporemenu" onClick={handleToggle}>
						    <span className="" role="button"><i className="fa fa-bars" aria-hidden="true"></i></span>
					    </button>
					    <div className="collapse navbar-collapse" id="singaporemenu">
					      <ul className="navbar-nav mx-auto">
					        <li className="nav-item">
					          <NavLink className="nav-link" to="/">HOME</NavLink>
					        </li>
					        <li className="nav-item">
					          <NavLink className="nav-link" to="/AboutUs">ABOUT US</NavLink>
					        </li>
					        <li className="nav-item">
					          <NavLink className="nav-link" to="/Products">PRODUCTS</NavLink>
					        </li>
					        <li className="nav-item">
					          <NavLink className="nav-link" to="/OurTeam">OUR TEAM</NavLink>
					        </li>
					        <li className="nav-item">
					          <NavLink className="nav-link" to="/Downloads">DOWNLOADS</NavLink>
					        </li>
					        <li className="nav-item">
					          <NavLink className="nav-link" to="/NewsAndInsights">NEWS &amp; INSIGHTS</NavLink>
					        </li>
					        <li className="nav-item">
					          <NavLink className="nav-link" to="/ContactUs">CONTACT US</NavLink>
					        </li>
					      </ul>
					    </div>
					  </div>
					</nav>
 				</div>
 			</div>
 		</div>
 	</div>
	 </>
  )
}

export default Navbar
