import React, { useEffect,useState } from 'react';
import { GetAllFooterContactUsSocialUserIconDetails } from "../../../api/FooterApi";
import { useFooterDataStore } from '../../zustand/footerDataStore';
import { Link } from 'react-router-dom';
import SETTING from '../../../modules/config/settings';

const FooterSocial = ({ setLoader4 }) => {

    const { socialIconsData, setContactUsSocialApiData } = useFooterDataStore(state => state);
    
    useEffect(() => {
        if (socialIconsData.length === 0) getAllFooterContactUsSocialIconDetails();
    }, [])


    const getAllFooterContactUsSocialIconDetails = async () => {
        try {
            setLoader4(true);
            const { data, status } = await GetAllFooterContactUsSocialUserIconDetails();
            if (status === 200) {
                setLoader4(false);
                if (data?.response?.status === 1) {
                    setContactUsSocialApiData(data?.response?.data?.contactUsSocialIconsDetails)
                } else {
                    setContactUsSocialApiData([])
                }
            }
            else {
                setContactUsSocialApiData([])
            }

        } catch (e) {
            setLoader4(false);
            console.log(e, "Error in Footer Socials");
        }
    }

    return (
    
            <div className="linkdin">
                <ul className="list-inline">
                    {
                    socialIconsData?.map(item => <li key={item.footerContactUsSocialId} className="list-inline-item">
                            <Link onClick={() => window.open(item.footerContactUsSocialIconLink, 'blank')}>
                                <img src={SETTING.IMAGE_API_URL_FOOTER_SOCIAL + item.footerContactUsSocialIconImage} alt={item.footerContactUsSocialIconName} className="img-fluid" />
                            </Link>
                        </li>)
                    }
                </ul>
            </div>

    )
}

export default FooterSocial