import React, { useState, useEffect } from 'react'
import DownloadComponent from './DownloadComponent'
import { Helmet } from 'react-helmet';
import { getAllDownloadDocuments } from '../../../api/DownloadApi';
import {Row, Col, Button,  } from 'react-bootstrap';
import {Modal, ModalBody,ModalHeader } from 'reactstrap'
import ViewBlack from '../../../assets/images/downloads/view.png'
import ViewWhite from '../../../assets/images/downloads/view-w.png'
import DownloadBlack from '../../../assets/images/downloads/download.png'
import DownloadWhite from '../../../assets/images/downloads/download-w.png'
import SETTING from '../../../modules/config/settings';
import moment from 'moment';
const DownloadDocuments = ({ setLoader4 }) => {

    const [downloadsData, setDownloadsData] = useState([])
    const [downloadsTitle, setDownloadsTitle] = useState('')
    const [docLevel1Id, setDocLevel1Id] = useState(null);
    const [docLevel2Id, setDocLevel2Id] = useState(null);
    const [docLevel3Id, setDocLevel3Id] = useState(null);
    const [docLevel4Id, setDocLevel4Id] = useState(null);
    const[document,setDocument] = useState([]);
    const [showModal,setShowModal] = useState(false);
    const[view,setView] = useState(null);

   var fileSaver = require('file-saver');

   const saveDocument = (file) => {
    fileSaver.saveAs(
      SETTING.DOWNLOAD_DOCUMENT_API_URL + file,
      file
    );

  };

    // eslint-disable-next-line
    const [viewIcon, setViewIcon] = useState(null);
    useEffect(() => {
        GetDownloadsData()
    }, [])

    const GetDownloadsData = async () => {

        setLoader4(true);
        await getAllDownloadDocuments().then(res => {

            if (res.status === 200) {
                if (res.data.response.status === 1) {
                    let downloadsDocs = res.data.response.data.downloadLevel1;
                    let downloadsTitle = res.data.response.data.downloadLevel1TitleName;
                    setDownloadsData(downloadsDocs);
                    setDownloadsTitle(downloadsTitle);
                }
            }

        }).catch(e => {
            setLoader4(false);
            console.log(e, "Error in the CEO Desk section from Homepage");
        }).finally(e => {
            setLoader4(false)
        });

    }

    useEffect(()=>{

    },[document])

    const handledocs1 = () => {
        setShowModal(true);

        let firstLeveldetails = downloadsData.find(item => item.downloadLevel1Id === docLevel1Id);
        let secondLeveldetails = firstLeveldetails?.downloadLevel2?.find(item => item.downloadLevel2Id === docLevel2Id);
        let thirdLeveldetails = secondLeveldetails?.downloadLevel3?.find(item => item.downloadLevel3Id === docLevel3Id);
        let fourthLeveldetails = thirdLeveldetails?.downloadLevel4?.find(item => item.downloadLevel4Id === docLevel4Id);

        if (docLevel1Id != null && docLevel2Id != null && docLevel3Id != null && docLevel4Id != null) {

            setDocument(fourthLeveldetails.downloadLevel5);

        } else if (docLevel1Id != null && docLevel2Id != null && docLevel3Id != null && docLevel4Id == null) {

            setDocument(thirdLeveldetails?.downloadLevel4[0]?.downloadLevel5);

        } else if (docLevel1Id != null && docLevel2Id != null && docLevel3Id == null && docLevel4Id == null) {

            setDocument(secondLeveldetails.downloadLevel3[0]?.downloadLevel4[0]?.downloadLevel5);

        } else if (docLevel1Id != null && docLevel2Id == null && docLevel3Id == null && docLevel4Id == null) {

            setDocument(firstLeveldetails.downloadLevel2[0]?.downloadLevel3[0]?.downloadLevel4[0]?.downloadLevel5);
        }

    }


    const openFile = (event,item)=>{
        event.preventDefault();
        window.open(`${SETTING.DOWNLOAD_DOCUMENT_API_URL}${item}`)
        
    }


   const handleClose = ()=>{
    setShowModal(false);
    setDocument([]);

   }

  return (
      <>
          <Helmet>
              <title>Downloads | Nippon Life India Asset Management (Singapore)</title>
              <meta name="description" content="Downloads: Download all the mutual fund scheme-related documents like factsheets, reports & KIID from Nippon Life India Asset Management (Singapore) website. Click here to download!" />
              <meta name="keywords" content="nippon life India asset management Singapore downloads, nippon Singapore downloads" />
          </Helmet>
          <section className="section-padding downloads-sec">
              <div className="container">
                  <div className="row">
                      <div className="col-12 mx-0 px-0">
                          <div className="bg-darkgrey">
                              <h2 className="text-uppercase">{downloadsTitle}</h2>
                          </div>
                      </div>
                      <div className="col-12 mx-0 px-0">
                          <ul className="nav nav-tabs" id="select-funds">{downloadsData.map(item => <li className="nav-item" key={item.downloadLevel1Id} onClick={() => (setDocLevel1Id(item.downloadLevel1Id), setDocLevel2Id(null), setDocLevel3Id(null), setDocLevel4Id(null))}>
                              <a className="nav-link fs" data-bs-toggle="tab">{item.downloadLevel1Name}</a>
                          </li>)}
                          </ul>
                      </div>
                      <>
                          {
                            downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadTotalLevels === 2 ?<>
                                  {docLevel1Id ? <><div className="bg-darkgrey">
                                      <Row>
                                          <Col>
                                              <h2 className="text-uppercase">{downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2TitleName}</h2>
                                          </Col>
                                          <Col  className="txt-rgt">
                                              <Button
                                                  variant="primary"
                                                  className="access-btn mx-2"
                                                  type="submit"
                                                  onClick={() => handledocs1()}
                                              >
                                                Archive
                                              </Button>{" "}
                                          </Col>
                                      </Row>
                                  </div>                                    
                                              <div className="tab-content view-download">
                                                  {
                                                      downloadsData.find(item => item.downloadLevel1Id === docLevel1Id).downloadLevel2[0].downloadLevel3[0].downloadLevel4[0].downloadLevel5.map((item,key) => key === 0 ? <DownloadComponent key={item.documentId} setViewIcon={setViewIcon} viewIcon={viewIcon} item={item} />:null)
                                                  }
                                              </div>   
                                  </> : null}
                              </> : <>
                                      {docLevel1Id ? <><div className="bg-darkgrey">
                                          <h2 className="text-uppercase">{downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2TitleName}</h2>
                                      </div> 
                                  <ul className="nav nav-tabs" id="select-funds-type" role="tablist">
                                      {

                                          downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2.map(item => <li className="nav-item" key={item.downloadLevel2Id} onClick={() => (setDocLevel2Id(item.downloadLevel2Id), setDocLevel3Id(null), setDocLevel4Id(null))}>
                                              <a className={`nav-link ${item.downloadLevel2Id === docLevel2Id ? "active" : ""}`} >{item.downloadLevel2Name}</a>
                                          </li>)
                                      }
                                </ul>
                                      </> : null}
                                          </>
                          }  
                      </>
                      <>
                          {
                               downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadTotalLevels === 3 ? <>
                                  {docLevel2Id ? <><div className="bg-darkgrey">
                                      <Row>
                                          <Col>
                                            <h2 className="text-uppercase">{downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2?.find(item => item.downloadLevel2Id === docLevel2Id)?.downloadLevel3TitleName}</h2>
                                          </Col>
                                          <Col  className="txt-rgt">
                                              <Button
                                                  variant="primary"
                                                  className="access-btn mx-2"
                                                  type="submit"
                                                  onClick={() => handledocs1()}
                                              >
                                                  Archive
                                              </Button>{" "}
                                          </Col>
                                      </Row>
                                  </div>                                    
                                             <div className="tab-content view-download">
                                                  {
                                                      downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2?.find(item => item.downloadLevel2Id === docLevel2Id)?.downloadLevel3[0]?.downloadLevel4[0]?.downloadLevel5.map((item,key) =>  key === 0 ?<DownloadComponent key={key} setViewIcon={setViewIcon} viewIcon={viewIcon} item={item} />:null)
                                                  }
                                              </div>                                       
                                  </> : null}
                              </> : <>
                                      {docLevel2Id ? <><div className="bg-darkgrey">
                                          <h2 className="text-uppercase">{downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2.find(item => item.downloadLevel2Id === docLevel2Id)?.downloadLevel3TitleName}</h2>
                                      </div>
                                  <ul className="nav nav-tabs" id="select-funds-type" role="tablist">
                                      {
                                              downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2?.find(item => item.downloadLevel2Id === docLevel2Id)?.downloadLevel3.map(item => <li className="nav-item" key={item.downloadLevel3Id} onClick={() => (setDocLevel3Id(item.downloadLevel3Id), setDocLevel4Id(null))}>
                                              <a className={`nav-link ${item.downloadLevel3Id === docLevel3Id ? "active" : ""}`} >{item.downloadLevel3Name}</a>
                                          </li>)

                                      }
                                          </ul></> : null}
                                  </>
                          }  
                      </>
                      <>
                      
                          {
                             downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadTotalLevels === 4 ? <>
                                  {docLevel3Id ? <><div className="bg-darkgrey">
                                      <Row>
                                          <Col>
                                              <h2 className="text-uppercase">{downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2.find(item => item.downloadLevel2Id === docLevel2Id)?.downloadLevel3.find(item => item.downloadLevel3Id === docLevel3Id)?.downloadLevel4TitleName}</h2>
                                          </Col>
                                          <Col className="txt-rgt">
                                              <Button
                                                  variant="primary"
                                                  className="access-btn mx-2"
                                                  type="submit"
                                                  onClick={() => handledocs1()}
                                              >
                                                Archive
                                              </Button>{" "}
                                          </Col>
                                      </Row>
                                
                                 
                                  </div>                                    
                                              <div className="tab-content view-download">
                                                  {
                                                      downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2.find(item => item.downloadLevel2Id === docLevel2Id)?.downloadLevel3.find(item => item.downloadLevel3Id === docLevel3Id)?.downloadLevel4[0]?.downloadLevel5.map((item,key) => key === 0 ? <DownloadComponent key={key} setViewIcon={setViewIcon} viewIcon={viewIcon} item={item} />:null)
                                                  }
                                              </div>
                                  </> : null}
                                  </>
                                      : <>
                                      {docLevel3Id ? <><div className="bg-darkgrey">
                                          <h2 className="text-uppercase">{downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2.find(item => item.downloadLevel2Id === docLevel2Id)?.downloadLevel3.find(item => item.downloadLevel3Id === docLevel3Id)?.downloadLevel4TitleName}</h2>
                                      </div> 
                                  <ul className="nav nav-tabs" id="select-funds-type" role="tablist">
                                      {

                                              downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2?.find(item => item.downloadLevel2Id === docLevel2Id)?.downloadLevel3.find(item => item.downloadLevel3Id === docLevel3Id)?.downloadLevel4.map(item => <li className="nav-item" key={item.downloadLevel4Id} onClick={() => (setDocLevel4Id(item.downloadLevel4Id))}>
                                              <a className={`nav-link ${item.downloadLevel4Id === docLevel4Id ? "active" : ""}`} >{item.downloadLevel4Name}</a>
                                          </li>)

                                      }
                                          </ul>
                                      </> : null}
                                      </>
                          }
                      </>
                      <>
                          {
                             downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadTotalLevels === 5 ? <>
                                  {docLevel4Id ? <><div className="bg-darkgrey">
                                      <Row >
                                          <Col>
                                            <h2 className="text-uppercase">{downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2.find(item => item.downloadLevel2Id === docLevel2Id)?.downloadLevel3.find(item => item.downloadLevel3Id === docLevel3Id)?.downloadLevel4.find(item => item.downloadLevel4Id === docLevel4Id)?.downloadLevel5TitleName}</h2>
                                          </Col>
                                          <Col  className="txt-rgt">
                                              <Button
                                                  variant="primary"
                                                  className="access-btn mx-2"
                                                  type="submit"
                                                  onClick={() => handledocs1()}
                                              >
                                                  Archive
                                              </Button>{" "}
                                          </Col>
                                      </Row>
                                  </div>                                     
                                              <div className="tab-content view-download">
                                                  {
                                                      downloadsData.find(item => item.downloadLevel1Id === docLevel1Id)?.downloadLevel2.find(item => item.downloadLevel2Id === docLevel2Id)?.downloadLevel3.find(item => item.downloadLevel3Id === docLevel3Id)?.downloadLevel4.find(item => item.downloadLevel4Id === docLevel4Id)?.downloadLevel5.map((item, index) => (index === 0 ? <DownloadComponent key={index} setViewIcon={setViewIcon} viewIcon={viewIcon} item={item} /> : null))
                                                  }
                                              </div>                             
                                  </> : null}
                                  </>
                                      : <></>
                          }
                      </>            
                  </div>
              </div>
          </section>

          <div>
              <Modal
                  isOpen={showModal}
                  toggle={handleClose}
                //   backdrop={true}
                //   keyboard={false}
                  size='lg'
              >
                  <ModalHeader toggle={handleClose}>
                      <div>
                          Documents
                      </div>
                  </ModalHeader>
                  <ModalBody className='modal-downloads'>
                    <div className='table-over'>
                      <table className='table '>
                          <thead>
                              <tr >
                                  <th className='text-center' width="10%">Sr.No.</th>
                                  <th className='text-center' width="15%">Date</th>
                                  <th className='text-center' width="33%">File Name</th>
                                  <th className='text-center' width="42%">Action</th>

                              </tr>
                          </thead>
                          <tbody className='table-group-divider'>
                          {
                            document.map((item,key) =>(
                                <tr>
                                    <td className='text-center'>{key + 1}</td>
                                    <td>{moment(item.downloadFileDate).format('MMM-YYYY')}</td>
                                    <td>{item.downloadFileName}</td>
                                    
                                    <td >
                                    <Button onClick={(e)=>openFile(e,item.documentName)} className="btn-view" >
                                            View
                                        </Button>{" "}
                                       
                                         <Button onClick={() => saveDocument(item.documentName)} className="btn-dwnld" >
                                            Download
                                        </Button>
                                      
                                      
                                    </td>
                                    
                                </tr>
                            ) )
                          }
                          </tbody>
                      </table></div>
                  </ModalBody>
              </Modal>
          </div>
      </>
  )
}

export default DownloadDocuments