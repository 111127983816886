import axios from 'axios';
import BaseUrl from '../modules/config/BaseUrl';


export const getOurTeamDescription = () => {
    return axios({
        method: 'GET',
        url: `${BaseUrl.UserApiUrl}/OurTeamUser/GetOurTeamContentParagraph`
    });
};

// export const post_putOurTeamDescription = (data) => {
//     return axios({
//         method: 'POST',
//         url: `${BaseUrl.UserApiUrl}/OurTeamAdmin/AddUpdateOurTeamContentParagraph`,
//         data: data
//     });
// };

