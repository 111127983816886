import React, { useState } from 'react'
import Footer from '../layouts/Footer';
import Header from '../layouts/Header';
import Homepage from '../sections/Home/Homepage';
import TopNavigation from '../layouts/TopNavigation';

const Home = () => {
  const [newLoading, setNewLoading] = useState(false)
  return (
    <div>
      <Header/>
      <TopNavigation/>
      <Homepage setNewLoading={setNewLoading} newLoading={newLoading}/>
      {newLoading === true &&(
      <>
      <Footer/>
      </>
      )}
    </div>
  )
}

export default Home
