import { create } from "zustand";
import { getallPagesNames } from '../../api/BannerApi'
export const usePageDataStore = create((set,get) => (
    {
        pageData: [],
        clearData:()=>(()=>({pageData:[]})),
        SET_PAGE_DATA: (data) => set(() => ({ pageData: data })),
        QUERY_PAGE_ID: async () => {
            try {

                const { status, data } = await getallPagesNames()
                const pages = data?.response;
                if (status === 200) {
                    if (pages?.status === 1) {
                        // setPages(pages?.data?.pageMaster);
                        const reducedData = pages?.data?.pageMaster?.map(({ active, isMultipleBanner, show, ...rest }) => ({ ...rest }))
                        set({ pageData: reducedData });
                    }
                }
            } catch (error) {
                console.log(error,'err in page names');
            }

        },
        GET_PAGE_ID:(pageName)=>{
           return get().pageData.filter((item) => pageName === item.pageName)[0]
        }
    }
));