import React, { useState,useEffect } from 'react'
import { getAllProductIconAndDescription } from "../../../../api/ProductApi";
import parse from 'html-react-parser';
import SETTING from "../../../../modules/config/settings";
import { useNavigate } from 'react-router-dom';

const Products = ({ setLoader4 }) => {

    const navigate = useNavigate();
    
    const[ products,setProducts]=useState([])
        
    useEffect(() => {
        getDataHandler();
    }, [])

    const getDataHandler = async () => {
        try {
            setLoader4(true)
            const { data, status } = await getAllProductIconAndDescription();
            if (status === 200) {
                setLoader4(false)
                if (data.response.status === 1) {
                    setProducts(data.response.data);
                } else {
                    setProducts([]);
                }
            }
            else {
                setProducts([]);
            }

        } catch (e) {
            setLoader4(false)
            console.log(e, "Error in Products section from Homepage");
        }
    }


    // setTimeout(() => {
    //     setLoader4(false)
    // }, 3000)

    // const toProductPage = (item) => {

    //     navigate(`/Products/${item.productDetailsId}`, { state: item });
    // }
    // const toProductPage = (item) => {

    //     navigate(`\Products`);
    // }


  return (
    <>
        <section className="section-padding home-products dot-bg bg-lightgreyy">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title text-center"><h1>Products</h1></div>
                    </div>
                </div>
                <div className="row">
                      {products.map((item,i) => <div key={i} className="col-lg-3 col-md-6 col-sm-12 ">
                          <div className="product-box"
                              onClick={() => navigate('\Products')}
                              style={{ cursor: "pointer" }}
                          >
                              <div className="product-box-img">
                                  <img src={`${SETTING.IMAGE_API_URL_PRODUCTS}${item.productIcon}`} alt="equity-strategy" className="img-fluid" />
                              </div>
                              {parse(item.productTitle)}
                          </div>
                      </div>)}
                 
                </div>
            </div>
        </section>
    </>
  )
}

export default Products