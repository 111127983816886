import React,{useState} from 'react'
import ViewBlack from '../../../assets/images/downloads/view.png'
import ViewWhite from '../../../assets/images/downloads/view-w.png'
import DownloadBlack from '../../../assets/images/downloads/download.png'
import DownloadWhite from '../../../assets/images/downloads/download-w.png'
import SETTING from '../../../modules/config/settings';
import { saveAs } from 'file-saver';

const DownloadComponent = ({ item, viewIcon, setViewIcon }) => {

  var fileSaver = require('file-saver');

  const saveDocument = () => {
    fileSaver.saveAs(
      SETTING.DOWNLOAD_DOCUMENT_API_URL + item.documentName,
      item.documentName
    );

  };

  return (

    <ul className="nav nav-tabs" key={item.documentId} id="factsheets-view1">
      <li className="nav-item">
        <a target="_blank" className="nav-link"
          onMouseOver={() => setViewIcon('view')} onMouseLeave={() => setViewIcon('')} onClick={(event) => { event.preventDefault(); window.open(`${SETTING.DOWNLOAD_DOCUMENT_API_URL}${item.documentName}`) }}>
          <img src={viewIcon === 'view' ? ViewWhite : ViewBlack} className="img-fluid view-b" alt='view-b' />
          View
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link"
          onMouseOver={() => setViewIcon('download')} onMouseLeave={() => setViewIcon('')} onClick={saveDocument} >
          <img src={viewIcon === 'download' ? DownloadWhite : DownloadBlack} className="img-fluid view-b" alt="download-b" />
          Download
        </a>
      </li>
    </ul>  
  )
}

export default DownloadComponent