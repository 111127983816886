import React, { useEffect } from 'react';
import { GetAllFooterContactUsDetails } from "../../../api/FooterApi";
import { useFooterDataStore } from '../../zustand/footerDataStore';

const FooterContact = ({setLoader3}) => {
    
    const { contactUsData, setContactUsApiData } = useFooterDataStore(state => state);

    useEffect(() => {
        if (contactUsData.length === 0) getAllFooterContactUsDetails();
    }, [])

    const getAllFooterContactUsDetails = async () => {
        try {
            setLoader3(true);
            const { data, status } = await GetAllFooterContactUsDetails();
            if (status === 200) {
                setLoader3(false);
                if (data?.response?.status === 1) {
                    setContactUsApiData(data?.response?.data.contactUsDetails)
                } else {
                    setContactUsApiData([])
                }
            }
            else {
                setContactUsApiData([])
            }

        } catch (e) {
            setLoader3(false);
            console.log(e, "Error in Footer Contact Details");
        }
    }

    return (

        <>
            {contactUsData?.map((item, id) => <p key={id} className="mb-3 no-select" >{item.contactUsName}<br />
                <span>{item.contactUsEmailId}</span><br />
                {item.contactUsPhoneNo !== "" ? <span >{item.contactUsPhoneNo}</span> : null}
            </p>)}
        </>

    )
}

export default FooterContact