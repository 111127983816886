import React from 'react'
import nam_logo from '../../assets/images/logo.jpg';
import { useNavigate } from 'react-router-dom';

const Header = () => {

  const navigate = useNavigate();

  return (
    <section className="header">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="singapore-bg">
             <img src={nam_logo} alt="nam_singapore_logo" className="singapore-logo img-fluid" onClick={()=>navigate("/")} style={{cursor:"pointer"}} />
            </div>
          </div>
        </div>
      </div>
 	  </section>
  )
}

export default Header
