import React, { useEffect, useState } from 'react';
import { GetAllFooterCopyright } from "../../../api/FooterApi";
import { useFooterDataStore } from '../../zustand/footerDataStore';

const FooterCopyright = ({ setLoader5 }) => {

    const { footerCopyrightData, setFooterCopyrightApiData } = useFooterDataStore(state => state);


    useEffect(() => {
        if (footerCopyrightData.length === 0) getAllFooterCopyright();
    }, [])


    const getAllFooterCopyright = async () => {
        try {
            setLoader5(true);
            const { data, status } = await GetAllFooterCopyright();
            if (status === 200) {
                setLoader5(false);
                if (data?.response?.status === 1) {
                    setFooterCopyrightApiData(data.response.data[0].footerContentParagraph)
              
                } else {
                    setFooterCopyrightApiData('')
                }
            }
            else {
                setFooterCopyrightApiData('')
            }

        } catch (e) {
            setLoader5(false);
            console.log(e, "Error in Footer Copyright");
        }
    }


    return (

        <>{footerCopyrightData}</>

    )
}

export default FooterCopyright

{/* {copyrightContentItems} */ }