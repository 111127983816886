import React, { useEffect } from 'react';
import { GetFooterContentParagraph } from "../../../api/FooterApi";
import { useFooterDataStore } from '../../zustand/footerDataStore';
import parse from 'html-react-parser';

const FooterDescription = ({setLoader1}) => {

    const { footerDescriptionData, setFooterDescriptionApiData } = useFooterDataStore(state => state);
    
    useEffect(() => {
        if (footerDescriptionData.length === 0) getFooterContentParagraph();
    }, [])
  
    const getFooterContentParagraph = async () => {
        try {
            setLoader1(true);
            const { data, status } = await GetFooterContentParagraph();
            if (status === 200) {
                setLoader1(false);
                if (data?.response?.status === 1) {
                    setFooterDescriptionApiData(data?.response?.data.footerContentParagraph)
                } else {
                    setFooterDescriptionApiData('')
                }
            }
            else {
                setFooterDescriptionApiData('')
            }
    
        } catch (e) {
            setLoader1(false);
            console.log(e, "Error in Footer description");
        }
    }

  return (
      <div className="footer-content-para">{parse(footerDescriptionData)}</div>
     
 
  )
}

export default FooterDescription