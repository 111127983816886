import axios from "axios";
import BaseUrl from '../modules/config/BaseUrl';

export const postContactUs = (data) => {
    return axios({
        method: "POST",
        url: `${BaseUrl.UserApiUrl}/ContactUsUser/AddContactUsDetails`,
        data: data
    });
};
export const getContactUsAllDetails = () => {
    return axios({
        method: "GET",
        url: `${BaseUrl.UserApiUrl}/ContactUsUser/GetAllContactUsDetails`,
       
    });
};
// export const getContactUsDetailsById = (id) => {
//     return axios({
//         method: "GET",
//         url: `${BaseUrl.UserApiUrl}/ContactUsUser/GetContactUsDetailsByID?ContactUsDetailsId=${id}`,
       
//     });
// };
// export const postContactUsDetails = (data) => {
//     return axios({
//         method: "POST",
//         url: `${BaseUrl.UserApiUrl}/ContactUsUser/AddContactUsDetails`,
//         data: data
//     });
// };
// export const deleteContactUsDetailsById = (id) => {
//     return axios({
//         method: "DELETE",
//         url: `${BaseUrl.UserApiUrl}/ContactUsUser/DeleteContactUsDetails?ContactUsDetailsId=${id}`,
       
//     });
// };
export const getContactUsAddress = (id) => {
    return axios({
        method: "GET",
        url: `${BaseUrl.UserApiUrl}/ContactUsUser/GetAllContactUsAddressDetails`,
       
    });
};


export const getAllContactSocials = () => {
    return axios({
        method: 'GET',
        url: `${BaseUrl.UserApiUrl}/ContactUsUser/GetAllContactUsSocialUserIconDetails`
    });
};
