import React, { useEffect } from 'react'
import { GetAllFooterIconAndDescriptions } from "../../../api/FooterApi";
import SETTING from '../../../modules/config/settings';
import { useFooterDataStore } from '../../zustand/footerDataStore';


const FooterSignatory = ({ setLoader5 }) => {

    const { footerSignatoryData, setFooterSignatoryApiData } = useFooterDataStore(state => state);

    useEffect(() => {
        if (footerSignatoryData.length === 0)  getDataIconHandler()
    }, [])


    const getDataIconHandler = async () => {
        try {
            setLoader5(true);
            const { data, status } = await GetAllFooterIconAndDescriptions();
            if (status === 200) {
                setLoader5(false);
                if (data?.response?.status === 1) {
                    
                    let filterData = data.response.data.filter((a)=>a.active === true)
                    setFooterSignatoryApiData(filterData);
                } else {
                    setFooterSignatoryApiData([]);
                }
            }
            else {
                setFooterSignatoryApiData([]);
            }
        } catch (error) {
            console.log(error);
            setLoader5(false);
        }
    }


    return (
        <>
            <div className="text-center">
                {footerSignatoryData.map((item) => 
                (
                    <div className='py-2'>
                        <img key={item.footerIconId} src={SETTING.IMAGE_API_URL_FOOTER + item.footerIcon} className="img-fluid footer-logo" alt='footer-logo' />
                    </div>
                ))}
            </div>
        </>
    )
}

export default FooterSignatory