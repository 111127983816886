export const settings = {
	// API_URL:"https://localhost:44305",
	// API_URL: 'http://namsapi.softlabsgroup.com', //live url
	// GOOGLE_MAP_URL: "https://goo.gl/maps/gfm9WCUiWitZjuRB6",
	// API_URL: "https://localhost:7110",

	// ICON_API_URL_ABOUT_US: "https://localhost:5010/AboutUs/Icons/", //local url
	// BANNER_IMAGE_API_URL: "https://localhost:5010/BannerImages/", //local url
	// DOWNLOAD_DOCUMENT_API_URL: "https://localhost:5010/Download/", //live url
	// IMAGE_API_URL_CONTACT_US: "https://localhost:5010/ContactUs/", //local url
	// IMAGE_API_URL_PRODUCTS: "https://localhost:5010/Product/", //local url
	// IMAGE_API_URL_WEARE: "https://localhost:5010/Qualities/", //local url
	// IMAGE_API_URL_NEWS: "https://localhost:5010/NSImages/", //local url
	// IMAGE_API_URL_FOOTER: "https://localhost:5010/Footer/", //local url
	// IMAGE_API_URL_FOOTER_SOCIAL: "https://localhost:5010/FooterSocialIcon/", //local url
	
	ICON_API_URL_ABOUT_US:"https://singapore.nipponindiaim.com/newwebsiteapi/AboutUs/Icons/", //live url
	BANNER_IMAGE_API_URL: "https://singapore.nipponindiaim.com/newwebsiteapi/BannerImages/", //live url
	DOWNLOAD_DOCUMENT_API_URL: "https://singapore.nipponindiaim.com/newwebsiteapi/Download/", //live url
	IMAGE_API_URL_CONTACT_US: "https://singapore.nipponindiaim.com/newwebsiteapi/ContactUs/", //live url
	IMAGE_API_URL_PRODUCTS: "https://singapore.nipponindiaim.com/newwebsiteapi/Product/", //live url
	IMAGE_API_URL_WEARE: "https://singapore.nipponindiaim.com/newwebsiteapi/Qualities/", //live url  
	IMAGE_API_URL_NEWS: "https://singapore.nipponindiaim.com/newwebsiteapi/NSImages/", //live url	
	IMAGE_API_URL_FOOTER: "https://singapore.nipponindiaim.com/newwebsiteapi/Footer/", //live url
	IMAGE_API_URL_FOOTER_SOCIAL: "https://singapore.nipponindiaim.com/newwebsiteapi/FooterSocialIcon/", //live url	


	//ICON_API_URL_ABOUT_US:"http://10.199.15.47:5011/imagesdoc/AboutUs/Icons/", //live url
	//BANNER_IMAGE_API_URL: "http://10.199.15.47:5011/imagesdoc/BannerImages/", //live url
	//DOWNLOAD_DOCUMENT_API_URL: "http://10.199.15.47:5011/imagesdoc/Download/", //live url
	//IMAGE_API_URL_CONTACT_US: "http://10.199.15.47:5011/imagesdoc/ContactUs/", //live url
	//IMAGE_API_URL_PRODUCTS: "http://10.199.15.47:5011/imagesdoc/Product/", //live url
	//IMAGE_API_URL_WEARE: "http://10.199.15.47:5011/imagesdoc/Qualities/", //live url
	//IMAGE_API_URL_NEWS: "http://10.199.15.47:5011/imagesdoc/NSImages/", //live url	
	//IMAGE_API_URL_FOOTER: "http://10.199.15.47:5011/imagesdoc/Footer/", //live url
	//IMAGE_API_URL_FOOTER_SOCIAL: "http://10.199.15.47:5011/imagesdoc/FooterSocialIcon/", //live url	

};

export default settings;