import axios from "axios";
import BaseUrl from '../modules/config/BaseUrl';
export const getBannerImagesByPageId = (pageId) => {
	return axios({
		method: 'GET',
        url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetPageBannersByID?PageID=${pageId}`
	});
};
export const getallPagesNames = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetAllPageNames`
	});
};


