import React, { useEffect, useState } from "react";
import { GetAllRulesRegulations } from "../../../api/FooterApi";
import parse from "html-react-parser";
import { Helmet } from 'react-helmet';
import Loader from '../../common/Loader/Loader';

const RulesAndRegulations = () => {
  const [rulesReguContentDescription, setRulesReguContentDescription] =
    useState("");
  const [rulesReguContentTitle, setRulesReguContentTitle] = useState("");

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getRulesRegulations();
  }, []);

  const getRulesRegulations = async () => {
    try {
      setLoader(true);
      const { data, status } = await GetAllRulesRegulations();
      if (status === 200) {
        setLoader(false);
        if (data?.response?.status === 1) {
          setRulesReguContentDescription(
            data?.response?.data[0].footerContentParagraph
          );
          setRulesReguContentTitle(
            data?.response?.data[0].footerContentHeading
          );
        } else {
          setRulesReguContentDescription("");
          setRulesReguContentTitle("");
        }
      } else {
        setRulesReguContentDescription("");
        setRulesReguContentTitle("");
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  return (
    <>{loader && <Loader />}
      <Helmet>
        <title>Rules and Regulations | Nippon Life India Asset Management (Singapore)</title>
        <meta name="description" content="Explore the rules and regulations governing Nippon LIfe India Asset Management Singapore. Gain insights into investment guidelines and compliance standards for informed decision-making!" />
        <meta name="keywords" content="nippon life india asset management singapore rules and regulations" />
      </Helmet>
      <section className="section-padding our-team-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                {parse(rulesReguContentTitle)}
              </div>
            </div>
            <div className="col-12">
              <div>{parse(rulesReguContentDescription)}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RulesAndRegulations;
