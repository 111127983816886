import React,{useEffect, useState} from 'react'
import { Link, useNavigate } from "react-router-dom";
import { getAllProductIconAndDescription} from "../../../api/ProductApi";
import SETTING from "../../../modules/config/settings";

function ProductsSection({ parser, setLoader2, setToTop }) {
    
  const navigate = useNavigate();
  const [productsItems, setProductsItems] = useState([])

  useEffect(() => {
      getDataHandler()
  }, [])
    

  const getDataHandler = async () => {
      try {
          setLoader2(true);
          const { data, status } = await getAllProductIconAndDescription();
          if (status === 200) {
              setLoader2(false);
              if (data?.response?.status === 1) {
                  setProductsItems(data?.response?.data);
                
              } else {
                  setProductsItems([]);
              }
          }
          else {
              setProductsItems([]);
          }

      } catch (e) {
        setLoader2(false);
        console.log(e, "Error in Products section");
      }
  }

 
  //  const toProductPage = (item) => {
  //       navigate(`/Downloads`);
  //       // navigate(`/Products/${item.productDetailsId}`, { state: item });
  //   } 

    
  return (
    <>
      <section className="section-padding bg-lightgrey">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-12 mx-auto">
              <div className="section-title text-center">
                <h1>Our Products</h1>
              </div>
              </div>
               {productsItems.map((item,i) =>
                                <div className="row justify-content-center product-sec" key={i}>
                                      <div className="col-lg-2 col-md-3 col-12">
                                          <div className="products-icons">
                                              <img src={`${SETTING.IMAGE_API_URL_PRODUCTS}${item.productIcon}`} className="img-fluid" alt="product-Img"  />
                                          </div>
                                      </div>
                                      <div className="col-lg-7 col-md-9 col-12">
                                          <div className="products-content" >
                                              <div>{ parser(item?.productTitle)}</div>
                                              {parser(item?.productShortDescription)}
                                          </div>
                                      </div>
                                  </div>)}
                              <div className="row justify-content-center">
                                  <div className="col-12 mx-auto">
                                      <div className="btn-singapore">
                          <Link onClick={(e) => setToTop(e,"/Downloads")} >View More</Link>
                                    </div>
                            </div>
                    </div>        
              </div>
            </div>
      </section>
    </>
  )
}

export default ProductsSection