import axios from 'axios';
import BaseUrl from '../modules/config/BaseUrl';



export const getAllProductIconAndDescription = () => {
    return axios({
        method: 'GET',
        url: `${BaseUrl.UserApiUrl}/Products/GetProductDetails`
    });
};