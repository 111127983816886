import axios from "axios";
import BaseUrl from '../modules/config/BaseUrl';
export const getCeoDeskData = (pageId) => {
    return axios({
        method: 'GET',
        url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetUserCEODeskDetails`
    });
};
export const getOurTeamData = (pageId) => {
    return axios({
        method: 'GET',
        url: `${BaseUrl.UserApiUrl}/AboutUsUser/GetUserOurTeamDetails`
    });
};