import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import OwlCarousel from 'react-owl-carousel';
import SETTING from '../../../modules/config/settings';
import { getBannerImagesByPageId } from '../../../api/BannerApi';
import SkeletonLoader from '../Skeleton/SkeletonLoader';

const ClickMultipleBanner = ({ imageAlt, pageId, setLoader1, setToTop,setNewLoading }) => {
    const [bannerData, setBannerData] = useState([]);
    const navigate = useNavigate()
    useEffect(() => {
        pageId && getBannerTableData(pageId)
    }, [pageId])

    const getBannerTableData = async (id) => {
        setLoader1(true);
        try {
            const { data, status } = await getBannerImagesByPageId(id);
            //Table data
            if (status === 200) {
                if (data.response.status === 1) {
                    setBannerData(data.response.data);
                } else {
                    setBannerData([]);
                }
            } else {
                setBannerData([]);
            }
            setLoader1(false);
        } catch (e) {
            setLoader1(false);
            console.log(e, "Error in Product Banner");
        }
    }

    // const toProductPage = () => {
    //     navigate(`/Downloads`);
    //     // navigate(`/Products/${item.productDetailsId}`, { state: item });
    // }

    const options = {
        loop: true,
        items: 1,
        autoplay: true,
        dots: true,
        autoplayTimeout: 6000,
        smartSpeed: 900,
        // animateOut: 'fadeOut'
        mouseDrag: false,
    };

    const handleImageLoad = ()=>{
        setNewLoading(true)
    }

    return (
        <section className="banner-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-0">
                        {bannerData.length < 1 ? (<SkeletonLoader variant="rectangular" width='100%' height={300} />) :
                            (<OwlCarousel id="pageBanner" className='owl-theme lg-12' {...options}>
                                {bannerData.map((val, i) => <img style={{ cursor: "pointer" }} src={SETTING.BANNER_IMAGE_API_URL + val.bannerImage} className="img-fluid" alt={imageAlt} key={i} onClick={(e) => setToTop(e,"/Downloads")} onLoad={handleImageLoad} />)}
                            </OwlCarousel>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ClickMultipleBanner