import React, { useState ,useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import Header from '../../../layouts/Header';
import TopNavigation from '../../../layouts/TopNavigation';
import Footer from '../../../layouts/Footer';
import outlook1 from '../../../../assets/images/news/outlook-box1.jpg';
import outlook2 from '../../../../assets/images/news/outlook-box2.jpg';
import outlook3 from '../../../../assets/images/news/outlook-box3.jpg';
import {outLookHistory} from '../../../../api/NewsAndInsightApi';
import { Pagination ,Stack } from "@mui/material";
import SETTING from "../../../../../src/modules/config/settings";

const NewsHistory = () => {


    const [newsData, setNewsData] = useState([]);
    const [pageC, setPageC] = useState(6)
    const [pageNumber, setPageNumber] = useState(1);
    const dataPerPage = 6;
//   const newsData = [
//     {
//         newsId: 1,
//         newsImg: outlook1,
//         newsDate: "- May 2022",
//         newsTitle: "Equity Market Outlook",
//         newsDescription: "Persistently high inflation owing to seemingly prolonged supply chain conundrums and resultant tightening worries have impact-ed global equities and bonds alike..."
//     },
//     {
//         newsId: 2,
//         newsImg: outlook2,
//         newsDate: "- May 2022",
//         newsTitle: "Fixed Income Market Outlook ",
//         newsDescription: "The month saw rising concerns about demand slowdown from China (Virus & related lockdown, moderation in growth projections) thereby raising concerns about global growth..."
//     },
//     {
//         newsId: 3,
//         newsImg: outlook3,
//         newsDate: "- May 2022",
//         newsTitle: "UCITS Equity Outlook",
//         newsDescription: "GDP- Slower global growth, adverse terms of trade shock, and impact on business confidence from geopolitical tensions weigh on the near term outlook On the other hand..."
//     },
//     {
//         newsId: 4,
//         newsImg: outlook1,
//         newsDate: "- May 2022",
//         newsTitle: "Equity Market Outlook",
//         newsDescription: "Persistently high inflation owing to seemingly prolonged supply chain conundrums and resultant tightening worries have impact-ed global equities and bonds alike..."
//     },
//     {
//         newsId: 5,
//         newsImg: outlook2,
//         newsDate: "- May 2022",
//         newsTitle: "Fixed Income Market Outlook ",
//         newsDescription: "The month saw rising concerns about demand slowdown from China (Virus & related lockdown, moderation in growth projections) thereby raising concerns about global growth..."
//     },
//     {
//         newsId: 6,
//         newsImg: outlook3,
//         newsDate: "- May 2022",
//         newsTitle: "UCITS Equity Outlook",
//         newsDescription: "GDP- Slower global growth, adverse terms of trade shock, and impact on business confidence from geopolitical tensions weigh on the near term outlook On the other hand..."
//     }
// ]

  const navigate = useNavigate();

  useEffect(() => {
    // alert("magic")
    window.scrollTo(0, 0);
    historyData(1,6);
  }, [])
  
  const backToHome = () => {
    console.log("back to home")
    navigate("/")
  }
  const toNewsItem = (e, item) => {
    e.preventDefault();
    navigate(`/NewsAndInsights/${item.newsId}`, { state: item });
  }


  const ChangePage = (e, a) => {
    setPageNumber(a);
    historyData(a, dataPerPage);

  }
  
  const historyData = (a, b) => {
    outLookHistory(a, b)
      .then((result) => {
        if (result.data.response.status === 1) {
          let res = result.data.response.data.outlookdetails
          let count = result.data.response.data.count;
          let APIpagecount = Math.ceil(count / dataPerPage);
          setPageC(APIpagecount);
          setNewsData(res);
          setPageNumber(a);

        }
      }).catch((err) => {
        console.log(err, 'err in outlook history');

      });
  }

  return (
    <div>
      <Header />
      <TopNavigation />
      <section className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title text-center">
                            <h1>Historic News and <span className="text-red">Insights</span></h1>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    {
                        newsData.map(item =>
                            <div key={item.outlookNewsId} className="col-lg-4 col-md-4 col-12 mt-4">
                            <div className="outlook-box bg-lightgrey">
                              <div className="outlook-box-img">
                                <img src={`${SETTING.IMAGE_API_URL_NEWS}${item.outlookIcon}`} alt='newsImg' className="img-fluid" />
                              </div>
                              <div className="outlook-box-content">
                                <h3>{item.outlookJoinTitle}</h3>
                                <p>{item.newsInsightDescription}</p>
                                <div className="btn-singapore">
                                  {
                                    item.newsInsightsButtonCode === 2 ?
                                    <a style={{ color: "#E00025" }} target='_blank' onClick={(event) => { event.preventDefault(); window.open(`${SETTING.IMAGE_API_URL_NEWS}${item.outlookPdfName}`) }}>{item.newsInsightButtonName}</a>
                                    :
                                    <a  onClick={(event) => { event.preventDefault(); window.open(item.newsInsightButtonLink) }}>{item.newsInsightButtonName}</a>
                                  }                               
                                </div>
                              </div>
                            </div>
                            </div>
                        )
                    }                    
                </div>
            </div>
        <br />
        <div>
          <Stack spacing={2} direction="row" justifyContent="center" id="pagination1">
            <Pagination
              count={pageC}
              page={pageNumber}
              onChange={ChangePage}
              color="primary"
              size="large"
              className="pagination-1"
              variant="outlined"
            />
          </Stack>

        </div>
        </section>
        {/* <div style={{ textAlign: "center" }} class="form-group"><button onClick={() => backToHome()}  className="btn btn-submit">Back To Home</button></div> */}
      <Footer />
    </div>
  )
}

export default NewsHistory
