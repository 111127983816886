import React from 'react'
import redHalfCircle from "../../../assets/images/news/red-halfcircle.png";
import SETTING from "../../../modules/config/settings";

const WeekChart = ({ parser, title, description, buttonActive, buttonName, buttonLink, iconActive, iconImage, backgroundId, buttonCode,
	documnetLink }) => {
  return (
	  <section className={`section-padding pb-0 ${backgroundId === 1 ? "bg-lightgrey" : backgroundId === 2 ? "bg-lightgreyy half-section" : backgroundId === 4 ? "half-section" : ""}`}>
 		<div className="container">
 			<div className="row">
 				<div className="col-12">
 					<div className="section-title text-center">
						  {parser(title)}
 					</div>
 				</div>
 				<div className="col-md-10 col-12 mx-auto para">
					  {parser(description)}
					  <div className="btn-singapore my-5">
						  {buttonActive && buttonCode === 2 ? <a target='_' onClick={(event) => { event.preventDefault(); window.open(`${SETTING.IMAGE_API_URL_NEWS}${documnetLink}`) }}>{buttonName}</a> : buttonActive && buttonCode === 1 ? <a target='_' href={buttonLink}>{buttonName}</a> : null}
					  </div>
 				</div>
				  {iconActive ?<><div className="col-md-8 col-12 text-center mx-auto">
					  <img src={`${SETTING.IMAGE_API_URL_NEWS}${iconImage}`} className="img-fluid" alt='week_chart_image'/>
 				</div>
 				<div className="col-12">
 					<div className="halfred-circle text-center">
 						<img src={redHalfCircle} className="img-fluid" alt='red_circle_image'/>
 					</div>
					  </div></>:null}
 			</div>
 		</div>
 	</section>
  )
}

export default WeekChart
