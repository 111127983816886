import React,{useState ,useEffect} from 'react';
import Carousel from "react-multi-carousel";
import OutlookItems from './OutlookItems';
import "react-multi-carousel/lib/styles.css";
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';


// const firstIndex = 0;


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
        slidesToSlide: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};


const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div className="carousel-button-group">
            <Button className={currentSlide === 0 ? ' owl-prev disable' : 'owl-prev'} onClick={() => previous()} />
            <Button className="owl-next" onClick={() => next()} />

        </div>
    );
};


const Outlook = ({ parser, title, backgroundId, newsData }) => {

    const navigate = useNavigate();
    // const [pageSize, setPageSize] = React.useState(3);
    // const [data, setData] = React.useState(newsData.slice(firstIndex, pageSize));

    // const handleChange = (event, value) => {
    //     setPage(value);
    //     setData(newsData.slice(firstIndex + pageSize * (value - 1), pageSize * value));
    // };
    // const toNewsItem = (e, item) => {
    //     e.preventDefault();
    //     navigate(`/NewsAndInsights/${item.newsId}`, { state: item });
    // }
    const newsHistory = () => {
        navigate("/NewsHistory")
    }



    return (



        <><section className={`section-padding ${backgroundId === 1 ? "bg-lightgrey" : backgroundId === 2 ? "bg-lightgreyy half-section" : backgroundId === 4 ? "half-section" : ""}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title text-center">
                                {parser(title)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="outlook react-multi col-12">
                        <Carousel responsive={responsive} draggable={false} arrows={false} showDots={false} renderButtonGroupOutside={true} infinite={true}
                            customButtonGroup={<ButtonGroup />}
                            autoPlay={true}
                            autoPlaySpeed={7000}
                            keyBoardControl={true}
                            customTransition="all 1000ms" transitionDuration="1000" dotListClass="custom-dot-list-style">
                            {newsData && newsData.map(item => <OutlookItems key={item.outlookNewsId} item={item} />)}

                        </Carousel>

                    </div>
                   
                        
                    </div>                
                    <div className='row'>
                        <div className='col-12 pt-5'>
                        <div style={{ textAlign: "center" }} className="form-group">
                        <button onClick={() => newsHistory()} className="btn btn-submit">Historic News</button>
                    </div>                     
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Outlook