import React from 'react'
import SETTING from "../../../modules/config/settings";

const WeeklyNewsUpdates = ({ parser, title, description, buttonActive, buttonName, buttonLink, iconActive, iconImage, backgroundId, buttonCode,
	documnetLink }) => {
	
  return (
	  <section className={`section-padding ${backgroundId === 1 ? "bg-lightgrey" : backgroundId === 2 ? "bg-lightgreyy half-section" : backgroundId === 4 ? "half-section" : ""}`}>
		<div className="container">
			<div className="row">
				<div className="col-12">
					<div className="section-title text-center">
						  {parser(title)}
					</div>
				  </div>
				  {!iconActive ? <><div className="col-sm-10 col-12 mx-auto para">
					  {parser(description)}
				  </div>
					  <div className="col-12 mx-auto mt-5">
						  <div className="btn-singapore">
							  {buttonActive && buttonCode === 2 ? <a target='_' onClick={(event) => { event.preventDefault(); window.open(`${SETTING.IMAGE_API_URL_NEWS}${documnetLink}`) }}>{buttonName}</a> : buttonActive && buttonCode === 1 ? <a target='_' href={buttonLink}>{buttonName}</a> : null}
						  </div>
					  </div></> :
					  <div className="row justify-content-center">
						  <div className="col-lg-6 col-md-6 col-12 align-content-center align-self-center">
							  <div className="weekly-news-div para">
								  {parser(description)}
								  {buttonActive ?
									  <div className="btn-singapore mt-5">
										  {buttonCode === 2 ? <a target='_' onClick={(event) => { event.preventDefault(); window.open(`${SETTING.IMAGE_API_URL_NEWS}${documnetLink}`) }}>{buttonName}</a> : <a target='_' href={buttonLink}>{buttonName}</a>}
									  </div>
									  : null}
							  </div>
						  </div>
						  <div className="col-lg-5 col-md-6 col-12 align-content-center align-self-center half-circle-div-mobile">
							  <div className="half-circle-div">
								  <img src={`${SETTING.IMAGE_API_URL_NEWS}${iconImage}`} className="img-fluid" alt='weekly_news_image' />
							  </div>
						  </div>
					  </div>}
			</div>
		</div>
 	</section>
  )
}

export default WeeklyNewsUpdates
