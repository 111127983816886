import React, { useState } from 'react'
import Footer from '../layouts/Footer';
import Header from '../layouts/Header';
import Content from '../sections/AboutUs/AboutUs';
import TopNavigation from '../layouts/TopNavigation';

const AboutUs = () => {
  const [newLoading, setNewLoading] = useState(false)
  return (
    <div>
      <Header/>
      <TopNavigation/>
      <Content setNewLoading={setNewLoading} newLoading={newLoading}/>
      {newLoading === true &&(
      <>
      <Footer/>
      </>
      )}
    </div>
  )
}

export default AboutUs
