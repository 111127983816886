import React, { useEffect, useState } from 'react'
import parse, { Element } from 'html-react-parser';
import ProductsSection from './ProductsSection';
import ClickMultipleBanner from '../../common/Banner/ClickMultipleBanner';
import { usePageDataStore } from '../../zustand/pageDataStore';
import Loader from '../../common/Loader/Loader';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom'

const Products = ({setNewLoading,newLoading}) => {

  const navigate = useNavigate();

  const {pageData,GET_PAGE_ID,QUERY_PAGE_ID} = usePageDataStore(state=>state);

  const [pageId,setPageId] = useState('');
  const [loader1,setLoader1] = useState(true);
  const [loader2,setLoader2] = useState(true);
  const parser = input =>
  parse(input, {
    replace: domNode => {
      if (domNode instanceof Element && domNode.attribs.class === 'remove') {
        return <></>;
      }
    }
  });
  useEffect(() => {
     //get a page id from zustand store
     const pageCall = async () => {
      if (pageData?.length < 1) {
        await QUERY_PAGE_ID();
      }
      const Id = GET_PAGE_ID("Products");
      setPageId(Id.pageId)
    }
    pageCall();
    // eslint-disable-next-line
  }, []);


  const setToTop = (e, nav) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate(`${nav}`);
  }

  return (
    <>
      {/* {[loader1, loader2].includes(true) && <Loader />} */}
      <Helmet>
        <title>Products| Nippon Life India Asset Management (Singapore)</title>
        <meta name="description" content="Unlock a world of investment possibilities with Nippon India Mutual Fund's diverse range of products in Singapore. Discover tailored solutions to meet your financial goals & aspirations!" />
        <meta name="keywords" content="nippon life india asset management singapore products" />
      </Helmet>
      <ClickMultipleBanner imageAlt={`Product_Banner`} pageId={pageId} setLoader1={setLoader1} setToTop={setToTop} setNewLoading={setNewLoading}/>
      {newLoading &&
      <ProductsSection imageAlt={`Product_Image`} parser={parser} setLoader2={setLoader2} setToTop={setToTop} />
      }
      </>
  )
}

export default Products
