import React from 'react'
import parse from 'html-react-parser';
import {Link} from 'react-router-dom'
import { getDownloadDescription } from '../../../../api/DownloadApi';

const Downloads = ({ setLoader8, setToTop }) => {
    const [paraData, setParaData] = React.useState('');

    React.useEffect(() => {
        GetDescription();
    }, []);

    setTimeout(() => {
        setLoader8(false)
    }, 5000)

    const GetDescription = async () => {
        try {
            setLoader8(true);
            const { data, status } = await getDownloadDescription();
            if (status === 200) {
                if (data.response.status === 1) {
                    setParaData(data.response.data.downloadHomeContentParagraph);
                } else {
                    setParaData("");
                }
            }
            else {
                setParaData("");
            }
            setLoader8(false);

        } catch (e) {
            setLoader8(false);
            console.log(e, "Error in the Downloads description from Homepage");
        }
    }

  return (
      <> <section className="section-padding">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <div className="section-title text-center">
                          <h1>Downloads</h1>
                      </div>
                  </div>
                  <div className="col-md-8 col-12 mx-auto">
                      <div className="download-box para">
                          {paraData && parse(paraData)}
                      </div>
                      <div className="btn-singapore">
                          <a onClick={(e) => setToTop(e, '/Downloads')}>Read More</a>
                      </div>
                  </div>
              </div>
          </div>
      </section></>
  )
}

export default Downloads