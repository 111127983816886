const metaConfig = {
  '/': {
    title:
      'Mutual Funds Services Online in Singapore | Nippon Life India Asset Management (Singapore)',
    description:
      'We at Nippon Life India Asset Management (Singapore) offer investment opportunities that help you build your financial portfolio. Click here to know more & invest in mutual funds online now!',
    keywords: [
      'nippon life India',
      'nippon India asset management company',
      'nippon India asset management',
      'nippon life India am ltd',
      'nippon India asset management limited',
      'nippon life Singapore',
    ],
  },
  '/AboutUs': {
    title:
      'About Nippon Life India Asset Management Singapore | Nippon Life India Asset Management (Singapore)',
    description:
      'Nippon Life India Asset Management (Singapore) is one of the best fund management companies in Singapore. Visit Nippon Life India Asset Management (Singapore) to know more about us!',
    keywords: ['international funds'],
  },
  '/ContactUs': {
    title: 'Contact Details | Nippon Life India Asset Management (Singapore)',
    description:
      'Get in touch with our dedicated team at Nippon Life India Asset Management (Singapore) for any investment related queries and/or feedbacks. Click to know more about our contact details!',
    keywords: [
      'nippon life India asset management Singapore contact',
      'nippon Singapore contact',
    ],
  },
  '/Disclaimer': {
    title: 'Disclaimer | Nippon Life India Asset Management (Singapore)',
    description:
      "Disclaimer: We at Nippon Life India Asset Management (Singapore) respect investors/customers/clients' right to privacy. Click here to read our disclaimer!",
    keywords: [
      'nippon life India asset management Singapore disclaimer',
      'nippon Singapore disclaimer',
    ],
  },
  '/Downloads': {
    title: 'Downloads | Nippon Life India Asset Management (Singapore)',
    description:
      'Downloads: Download all the mutual fund scheme-related documents like factsheets, reports & KIID from Nippon Life India Asset Management (Singapore) website. Click here to download!',
    keywords: [
      'nippon life India asset management Singapore downloads',
      'nippon Singapore downloads',
    ],
  },
  '/OurTeam': {
    title: 'Our Team | Nippon Life India Asset Management (Singapore)',
    description:
      'Read about the team members at Nippon Life India Asset Management (Singapore). Click to learn more about us & invest in mutual funds online today!',
    keywords: [
      'nippon life India asset management Singapore team',
      'team of  nippon life India asset management Singapore',
    ],
  },
  '/RulesAndRegulations': {
    title:
      'Rules and Regulations | Nippon Life India Asset Management (Singapore)',
    description:
      'Explore the rules and regulations governing Nippon LIfe India Asset Management Singapore. Gain insights into investment guidelines and compliance standards for informed decision-making!',
    keywords: [
      'nippon life india asset management singapore rules and regulations',
    ],
  },
  '/Products': {
    title: 'Products| Nippon Life India Asset Management (Singapore)',
    description:
      "Unlock a world of investment possibilities with Nippon India Mutual Fund's diverse range of products in Singapore. Discover tailored solutions to meet your financial goals & aspirations!",
    keywords: ['nippon life india asset management singapore products'],
  },
  '/NewsAndInsights': {
    title: 'News and Insights| Nippon Life India Asset Management (Singapore)',
    description:
      'Keep abreast of the latest developments & gain valuable insights into the world of finance with Nippon Life India Asset Management Singapore. Stay ahead in your investment journey!',
    keywords: ['nippon life india asset management singapore news'],
  },
};

export default metaConfig;
