import React,{ useState, useEffect } from 'react';
import { getAboutUsDescription } from '../../../api/AboutUsApi';
const AboutUsParagraph = ({parser, setLoader2}) => {

  const [data, setData] = useState('');
  const [loader,setLoader] = useState(false);
  
  useEffect(() => {
    GetDescription();
  }, [])

  const GetDescription = async () => {
    try {
      setLoader2(true);
      const { data, status } = await getAboutUsDescription();
      if (status === 200) {
        setLoader2(false);
        if (data.response.status === 1) {
          setData(data.response.data.aboutUsContentParagraph);
        } else {
          setData("");
        }
      }
      else {
        setData("");
      }
      setLoader2(false);
    } catch (e) {
      setLoader2(false);
      console.log(e, "Error in About Us description");
    }
  }

  return (
    <>
      {
      <section className="section-padding bg-lightgrey">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-12 mx-auto">
                <div className="section-title text-center">
                  <h1>About <span className="text-red">Us</span></h1>
                </div>
                <div className="about-para">
                  {parser(data)}
                </div>
            </div>
          </div>
        </div>
      </section>}
    </>
  )
}

export default AboutUsParagraph